export const getters = {
  totalsItems: (state) => state.totalsItems,
  items: (state) => state.items,
  notes: (state) => state.notes,
  isNotes: (state) => state.isNotes,
  loading: (state) => state.loading,
  options: (state) => state.options,
  filters: (state) => state.filters,
  hasProductsSuitableSubscription: (state) =>
    state.hasProductsSuitableSubscription,
};
