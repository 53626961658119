<template>
  <base-modal
    :is-visible="isOpened"
    class="tariff-popup"
    @closeModal="closeModal"
  >
    <template>
      <div class="tariff-header">Оплата на расчётный счёт</div>
      <div class="tariff-body">Напишите в чат с поддержкой</div>
      <div class="tariff-body">
        <li>Свой email</li>
        <li>Нужный тариф</li>
        <li>Кол-во месяцев</li>
        <li>Реквизиты (ИНН, КПП, Р/С, БИК)</li>
      </div>

      <a href="https://t.me/WBLead_dev" target="_blank">
        <button class="apply-btn">
          <a>Перейти в чат с поддержкой</a>
        </button>
      </a>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from '@/views/reports/report-6/components/modals/BaseModal.vue';

export default {
  name: 'TariffUpPopup',
  components: { BaseModal },
  emits: ['close'],
  props: ['isOpened'],
  methods: {
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.tariff-popup {
    width: 420px;
    height: 300px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    position: fixed;
}

.tariff-header {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 15px;
}

.tariff-body {
    margin-bottom: 15px;
}

.apply-btn {
  background-color: #541CAB;
  color: white;
  border: none;
  border-radius: 10px;
  width: 100%;
    height: 50px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        text-decoration: none;
    }
}
</style>
