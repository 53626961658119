import axios from 'axios';

const state = () => ({
  value: [],
});
const getters = {
  tableCacheValue(state) {
    return state.value;
  },
};
const mutations = {
  SET_VALUE(state, payload) {
    state.value = payload;
  },
};
const actions = {
  setTableCache({ commit, state }, options) {
    const data = { ...options };
    axios({
      url: '/new-reports/cache/set/',
      method: 'POST',
      data: data,
    })
      .then((response) => {})
      .catch((err) => {
        console.error(err);
      });
  },
  getTableCache({ commit, state }, options) {
    const data = { ...options };
    axios({
      url: '/new-reports/cache/get/',
      method: 'POST',
      data: data,
    })
      .then((response) => {
        commit('SET_VALUE', response.data.value);
      })
      .catch((err) => {
        console.error(err);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
