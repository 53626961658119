<template>
  <div class="table">
    <NewModalSettings
      v-if="columns.length"
      pageName="report-1"
      :columns="columns"
      :showZeros="getShowZeros()"
      @setShowZeros="setShowZeros"
      @setColumns="setColumns"
      ref="settingsModal"
    />
    <ag-grid-vue
      class="ag-theme-alpine custom-grid"
      style="width: 100%; height: 100%"
      :columnDefs="columns"
      :rowData="items"
      :gridOptions="gridOptions"
      :defaultColDef="defaultColDef"
      :pinnedBottomRowData="getPinnedBottomRowData()"
      :tooltipShowDelay="0"
      @grid-ready="onGridReady"
      @filter-changed="onFilterChanged"
      enableCellTextSelection="true"
      @column-resized="saveColumnStateDebounced"
      @column-moved="saveColumnStateDebounced"
    ></ag-grid-vue>
  </div>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue';
import { mapGetters, mapActions } from 'vuex';
import TableHeader from '@/components/ui/table/TableHeader.vue';
import TableFilterModal from '@/components/ui/table/TableFilterModal.vue';
import NewModalSettings from '@/components/ui/table/NewModalSettings.vue';
import TableHeaderSettings from '@/components/ui/table/TableHeaderSettings.vue';
import ImgTooltip from '@/components/images/ImgTooltip.vue';
import TableImageComponent from '@/components/ui/table/TableImageComponent';
import LinkCell from '@/components/ui/table/LinkCell.vue';
import { numberFormatter } from '@/helpers/numberFormatter';
import debounce from 'lodash/debounce';
export default {
  name: 'Table',
  props: ['items', 'totalsItems'],
  components: {
    AgGridVue,
    agColumnHeader: TableHeader,
    TableFilterModal,
    NewModalSettings,
    TableHeaderSettings,
    ImgTooltip,
    TableImageComponent,
    LinkCell,
  },
  data() {
    return {
      columns: [],
      gridOptions: {},
      defaultColDef: {
        resizable: true,
        width: 150,
        filter: false,
        sortable: true,
        unSortIcon: true,
        cellDataType: false,
        alwaysShowHorizontalScroll: true,
        alwaysShowVerticalScroll: true,
        headerComponentParams: {
          pageName: 'productsAdvertising',
        },
      },
      gridApi: null,
    };
  },
  computed: {
    ...mapGetters('productsAdvertising', ['options']),
    ...mapGetters('tableCache', ['tableCacheValue']),
  },
  watch: {
    tableCacheValue: {
      handler() {
        if (this.tableCacheValue) {
          this.gridApi.applyColumnState({
            state: JSON.parse(this.tableCacheValue),
            applyOrder: true,
          });
        }
      },
    },
  },
  created() {
    this.saveColumnStateDebounced = debounce(this.onColumnChanged, 1000);
  },
  methods: {
    ...mapActions('productsAdvertising', ['getData', 'setOptions']),
    ...mapActions('tableCache', ['setTableCache', 'getTableCache']),
    onFilterChanged() {
      const filterModel = this.gridApi.getFilterModel();
      localStorage.setItem(
        `productsAdvertising-filters`,
        JSON.stringify(filterModel)
      );
    },
    onColumnChanged(params) {
      const state = params.api.getColumnState();
      this.setTableCache({
        key: 'report-1',
        value: JSON.stringify(state),
      });
    },
    setShowZeros(show_zeros) {
      this.setOptions({
        show_zeros,
      });
      this.getData();
    },
    resetFilters() {
      this.gridApi.setFilterModel(null);
    },
    showSettingsModal() {
      this.$refs.settingsModal.open();
    },

    setColumns(columns) {
      this.gridApi.setGridOption('columnDefs', columns);
    },

    getShowZeros() {
      return this.options?.show_zeros ?? true;
    },

    getPinnedBottomRowData() {
      return [this.totalsItems];
    },
    getFieldName(prefix, name) {
      return prefix ? `${prefix}.${name}` : name;
    },
    getProductFinancialClmHeader(prefix) {
      if (prefix === 'auto_company') {
        return 'Расход по артикулу относительно автокампаний из отчёта "финансы" кабинета Вб.Продвижения за выбранный период.';
      } else if (prefix === 'search_and_catalog') {
        return 'Расход по артикулу относительно аукциона из отчёта "финансы" кабинета Вб.Продвижения за выбранный период.';
      } else if (prefix === 'total') {
        return 'Суммарный расход по артикулу относительно всех типов РК из отчёта "финансы" кабинета Вб.Продвижения за выбранный период.';
      }
    },
    getViewsClmHeader(prefix) {
      if (prefix === 'auto_company') {
        return 'Количество просмотров, по автокампаниям  за выбранный период.';
      } else if (prefix === 'search_and_catalog') {
        return 'Количество просмотров, по аукциону за выбранный период.';
      } else if (prefix === 'total') {
        return 'Количество просмотров, суммарное по обоим типам РК за выбранный период.';
      }
    },
    getClicksClmHeader(prefix) {
      if (prefix === 'auto_company') {
        return 'Количество кликов, по автокампаниям за выбранный период.';
      } else if (prefix === 'search_and_catalog') {
        return 'Количество кликов, по аукциону за выбранный период.';
      } else if (prefix === 'total') {
        return 'Количество кликов, суммарное по обоим типам РК за выбранный период.';
      }
    },
    getCpmClmHeader(prefix) {
      if (prefix === 'auto_company') {
        return '"Cost per mille" - (цена за 1000 показов) стоимость фактической ставки за 1000 показов в автокампанииях за выбранный период.';
      } else if (prefix === 'search_and_catalog') {
        return '"Cost per mille" - (цена за 1000 показов) стоимость фактической ставки за 1000 показов в аукционе за выбранный период.';
      } else if (prefix === 'total') {
        return '"Cost per mille" - (цена за 1000 показов) стоимость фактической ставки за 1000 показов по обоим типам РК за выбранный период.';
      }
    },
    getCpcClmHeader(prefix) {
      if (prefix === 'auto_company') {
        return '"сost per click" - (цена за клик) – цена клика по рекламируемому товару в автокампании за выбранный период.';
      } else if (prefix === 'search_and_catalog') {
        return '"сost per click" - (цена за клик) –  цена клика по рекламируемому товару в аукционе за выбранный период.';
      } else if (prefix === 'total') {
        return '"сost per click" - (цена за клик) – средняя цена клика по рекламируемому товару по всем типам РК за выбранный период.';
      }
    },
    getCtrClmHeader(prefix) {
      if (prefix === 'auto_company') {
        return '"сlick-through rate" – показатель кликабельности артикула, т. е. отношение числа кликов к количеству показов в рамках автокампании  за выбранный период.';
      } else if (prefix === 'search_and_catalog') {
        return '"сost per click" - (цена за клик) –  цена клика по рекламируемому товару в аукционе за выбранный период.';
      } else if (prefix === 'total') {
        return '"сost per click" - (цена за клик) – средняя цена клика по рекламируемому товару по всем типам РК за выбранный период.';
      }
    },
    getConsumptionClmHeader(prefix) {
      if (prefix === 'auto_company') {
        return 'Расход денежных средств на автоматические рекламные кампании за выбранный период относительно артикула. (из рекламной статистики ВБ)';
      } else if (prefix === 'search_and_catalog') {
        return 'Расход денежных средств на  рекламные кампании Поиск/Каталог за выбранный период относительно артикула. (из рекламной статистики ВБ)';
      } else if (prefix === 'total') {
        return 'Суммарный расход денежных средств на все виды рекламных кампаний за выбранный период относительно артикула. (из рекламной статистики ВБ)';
      }
    },
    getOrderClmHeader(prefix) {
      if (prefix === 'auto_company') {
        return 'Количество единиц заказанных товаров артикула по всем автоматическим рекламным кампаниям за выбранный период. (из рекламной статистики ВБ)';
      } else if (prefix === 'search_and_catalog') {
        return 'Количество единиц заказанных товаров артикула по всем  рекламным кампаниям (Поиск+Каталог) за выбранный период. (из рекламной статистики ВБ)';
      } else if (prefix === 'total') {
        return 'Суммарное количество единиц заказанных товаров  по всем видам рекламных кампаний за выбранный период. (из рекламной статистики ВБ)';
      }
    },
    getCartClmHeader(prefix) {
      if (prefix === 'auto_company') {
        return 'Количество единиц добавленных товаров в корзину артикула по всем автоматическим рекламным кампаниям за выбранный период. (из рекламной статистики ВБ)';
      } else if (prefix === 'search_and_catalog') {
        return 'Количество единиц добавленных товаров в корзину артикула по всем рекламным кампаниям Поиск/Каталог за выбранный период. (из рекламной статистики ВБ)';
      } else if (prefix === 'total') {
        return 'Суммарное количество единиц заказанных товаров  по всем видам рекламных кампаний за выбранный период. (из рекламной статистики ВБ)';
      }
    },
    getCplClmHeader(prefix) {
      if (prefix === 'auto_company') {
        return (
          '(«Cost per lead» - цена за лид). Стоимость одного добавления в корзину относительно артикула по автоматическим рекламным кампаниям за выбранный \n' +
          'период. (считаем формулой "расход,руб разделить на корзины в шт)'
        );
      } else if (prefix === 'search_and_catalog') {
        return (
          'CPL («Cost per lead» - цена за лид). Стоимость одного добавления в корзину относительно артикула по рекламным кампаниям Поиск/каталог за выбранный \n' +
          'период. (считаем формулой ""расход,руб разделить на корзины в шт)'
        );
      } else if (prefix === 'total') {
        return 'CPL («Cost per lead» - цена за лид). Стоимость одного добавления в корзину относительно артикула по всем рекламным кампаниям за выбранный период.';
      }
    },
    getCpoClmHeader(prefix) {
      if (prefix === 'auto_company') {
        return (
          '(«Cost per Order» - стоимость оформленного заказа). Стоимость одного оформленного заказа по автоматическим рекламным кампаниям относительно артикула за выбранный \n' +
          'период. (считаем формулой ""расход,руб разделить на заказы в шт)'
        );
      } else if (prefix === 'search_and_catalog') {
        return (
          '(«Cost per Order» - стоимость оформленного заказа). Стоимость одного оформленного заказа по рекламным кампаниям Поиск/Каталог относительно артикула за выбранный \n' +
          'период. (считаем формулой ""расход,руб разделить на заказы в шт)'
        );
      } else if (prefix === 'total') {
        return '(«Cost per Order» - стоимость оформленного заказа). Стоимость одного оформленного заказа по всем рекламным кампаниям относительно артикула за выбранный период. (считаем формулой ""расход,руб разделить на заказы в шт)';
      }
    },
    getSalesReports() {
      return [
        {
          field: 'sales_report.total_ordered_in_pieces',
          name: 'total_ordered_in_pieces',
          headerName: 'Заказано, шт',
          valueFormatter: numberFormatter,
        },
        {
          field: 'sales_report.total_ordered_in_rubles',
          name: 'total_ordered_in_rubles',
          headerName: 'Заказано, шт',
          valueFormatter: numberFormatter,
        },
        {
          field: 'sales_report.total_redeemed',
          name: 'total_redeemed',
          headerName: 'Выкуплено, шт',
          valueFormatter: numberFormatter,
        },
        {
          field: 'sales_report.total_to_transfer',
          name: 'total_to_transfer',
          headerName: 'Выручка с продаж, руб',
          valueFormatter: numberFormatter,
        },
        {
          field: 'sales_report.drr',
          name: 'drr',
          headerName: 'TACoO, % от суммы заказов',
          valueFormatter: numberFormatter,
        },
      ];
    },
    getItemColumns(prefix) {
      return [
        {
          field: this.getFieldName(`${prefix}`, 'consumption'),
          headerName: 'Расход по РК, руб',
          width: 110,
          name: 'consumption',
          headerTooltip: this.getConsumptionClmHeader(prefix),
          valueFormatter: numberFormatter,
          sort: prefix === 'total' ? 'desc' : false,
        },
        {
          field: this.getFieldName(prefix, 'product_financial_consumption'),
          headerName: 'Финансовый расход, руб.',
          width: 110,
          name: 'product_financial_consumption',
          headerTooltip: this.getProductFinancialClmHeader(prefix),
          valueFormatter: numberFormatter,
        },
        {
          field: this.getFieldName(`${prefix}`, 'views'),
          name: 'views',
          headerName: 'Просмотры, шт',
          width: 100,
          valueFormatter: numberFormatter,
          headerTooltip: this.getViewsClmHeader(prefix),
        },
        {
          field: this.getFieldName(`${prefix}`, 'clicks'),
          name: 'clicks',
          headerName: 'Клики, шт',
          width: 100,
          valueFormatter: numberFormatter,
          headerTooltip: this.getClicksClmHeader(prefix),
        },

        {
          field: this.getFieldName(`${prefix}`, 'cart'),
          name: 'cart',
          headerName: 'Корзина, шт',
          width: 100,
          headerTooltip: this.getCartClmHeader(prefix),
          valueFormatter: numberFormatter,
        },
        {
          field: this.getFieldName(`${prefix}`, 'order'),
          headerName: 'Заказ, шт',
          name: 'order',
          width: 100,
          headerTooltip: this.getOrderClmHeader(prefix),
          valueFormatter: numberFormatter,
        },

        {
          field: this.getFieldName(`${prefix}`, 'cpl'),
          name: 'cpl',
          headerName: 'CPL, руб.',
          width: 110,
          headerTooltip: this.getCplClmHeader(prefix),
          valueFormatter: numberFormatter,
        },
        {
          field: this.getFieldName(`${prefix}`, 'cpo'),
          name: 'cpo',
          headerName: 'CPO, руб',
          width: 110,
          headerTooltip: this.getCpoClmHeader(prefix),
          valueFormatter: numberFormatter,
        },
        {
          field: this.getFieldName(`${prefix}`, 'cpm'),
          name: 'cpm',
          headerName: 'CPM факт, руб',
          width: 110,
          valueFormatter: numberFormatter,
          headerTooltip: this.getCpmClmHeader(prefix),
        },
        {
          field: this.getFieldName(`${prefix}`, 'cpc'),
          name: 'cpc',
          headerName: 'CPC, руб',
          width: 110,
          valueFormatter: numberFormatter,
          headerTooltip: this.getCpcClmHeader(prefix),
        },
        {
          field: this.getFieldName(`${prefix}`, 'ctr'),
          name: 'ctr',
          headerName: 'средний CTR',
          width: 110,
          valueFormatter: numberFormatter,
          headerTooltip: this.getCtrClmHeader(prefix),
        },
      ];
    },
    generateTableColumns() {
      const savedSorting = localStorage.getItem('productsAdvertising-sort');
      this.columns = [
        {
          headerGroupComponent: 'TableHeaderSettings',
          headerGroupComponentParams: {
            showSettingsModal: this.showSettingsModal,
          },
          groupName: 'settings',
          children: [
            {
              field: 'image',
              cellRenderer: 'TableImageComponent',
              cellRendererParams: (params) => {
                return {
                  link: params.node.data.wb_catalog_url,
                };
              },
              width: 50,
              headerName: 'Фото',
              pinned: 'left',
              tooltipField: 'image',
              tooltipComponent: 'ImgTooltip',
              sortable: false,
              disableCheckbox: true,
            },
            {
              field: 'nm_id',
              headerName: 'Артикул WB',
              width: 120,
              name: 'nm_id',
              pinned: 'left',
              filter: 'TableFilterModal',
              cellRenderer: 'LinkCell',
              cellRendererParams: (params) => {
                return {
                  link: `/reports/products-statistic/${params.value}`,
                  targetBlank: true,
                };
              },
            },
            {
              field: 'sa_name',
              headerName: 'Артикул поставщика',
              name: 'sa_name',
              pinned: 'left',
              filter: 'TableFilterModal',
              width: 140,
            },
            {
              field: 'subject',
              headerName: 'Предмет',
              width: 130,
              name: 'subject',
              filter: 'TableFilterModal',
            },
            {
              field: 'brand',
              name: 'brand',
              width: 130,
              headerName: 'Бренд',
              filter: 'TableFilterModal',
            },
          ],
        },
        {
          headerName: '',
          groupName: 'tacos',
          children: [
            {
              field: 'sales_report.tacos',
              name: 'tacos',
              headerName: 'прогнозируемый TACoS, %',
              headerTooltip:
                '(Total Advertising Cost of Sale - общая стоимость выручки на рекламу). Расходы на рекламу по отношению к общей прогнозируемой выручке ((расходы на рекламу, руб/общий объём прогнозируемой выручки)*100) %.  За выбранный период.',
              valueFormatter: numberFormatter,
              width: 140,
              pinned: 'left',
            },
          ],
        },
        {
          headerName: 'Автоматическая',
          groupName: 'auto_company',
          children: this.getItemColumns('auto_company'),
        },
        {
          headerName: 'Аукцион',
          groupName: 'search_and_catalog',
          children: this.getItemColumns('search_and_catalog'),
        },
        {
          headerName: 'Итого',
          groupName: 'total',
          children: [
            ...this.getItemColumns('total'),
            {
              field: 'total.total_ordered_in_rubles_drr',
              name: 'total_ordered_in_rubles_drr',
              headerName: 'Сумма заказов, руб',
              width: 110,
              valueFormatter: numberFormatter,
              headerTooltip:
                'Сумма всех заказов по артикулу в рублях по всем типам РК',
            },
            {
              field: 'total.drr',
              name: 'drr',
              headerName: 'ДРР по рекламным заказам ВБ, %',
              valueFormatter: numberFormatter,
              width: 120,
              headerTooltip:
                'Доля рекламных расходов, процент затрат на рекламу относительно заказов по рекламной кампании за выбранный период. Формула: ((расход на рекламу, руб/заказы по рекламе, руб)*100)) %',
            },
          ],
        },
        {
          headerName: 'Отчет ВБ',
          groupName: 'sales_report',
          children: [
            {
              field: 'sales_report.total_ordered_in_pieces',
              headerName: 'Заказано, шт.',
              width: 100,
              name: 'total_ordered_in_pieces',
              headerTooltip:
                'Суммарное количество единиц заказанного товара в штуках за выбранный период (из воронки продаж ВБ. Совпадает с отчётом "Еженедельная динамика и анализ продаж")',
              valueFormatter: numberFormatter,
            },
            {
              field: 'sales_report.total_ordered_in_rubles',
              headerName: 'Заказано, руб.',
              width: 110,
              name: 'total_ordered_in_rubles',
              headerTooltip:
                'Суммарное количество заказанного товара в рублях за выбранный период (из воронки продаж ВБ. Сервис тянет каждый заказ по сделанной в моменте цене)',
              valueFormatter: numberFormatter,
            },
            {
              field: 'sales_report.order_price',
              name: 'order_price',
              width: 140,
              headerName: 'Средняя цена заказа, руб.',
              valueFormatter: numberFormatter,
              headerTooltip:
                'Средняя цена за один заказ по артикулу за выбранный период (средний чек заказа)',
            },
            {
              field: 'sales_report.total_redeemed',
              name: 'total_redeemed',
              headerName: 'Выкуплено, шт.',
              width: 100,
              headerTooltip:
                'Суммарное количество единиц выкупленного товара в штуках за выбранный период (ориентир - воронка продаж ВБ. Совпадает с отчётом "Еженедельная динамика и анализ продаж")',
              valueFormatter: numberFormatter,
            },

            {
              field: 'sales_report.total_to_transfer',
              name: 'total_to_transfer',
              headerName: 'Выручка с продаж, руб',
              valueFormatter: numberFormatter,
              headerTooltip:
                'Суммарное количество выкупленного товара в рублях за выбранный период (из воронки продаж ВБ. Сервис тянет каждую продажу по сделанной в моменте цене)',
              width: 140,
            },
            {
              field: 'sales_report.buyout_percent',
              name: 'buyout_percent',
              headerName: '% выкупа',
              width: 100,
              valueFormatter: numberFormatter,
              headerTooltip:
                '% выкупа товара за выбранный период, берём из воронки продаж (для большей точности - не день в день, а с вычетом условного периода атрибуции).',
            },
            {
              field: 'sales_report.projected_revenue',
              name: 'projected_revenue',
              headerName: 'Прогнозируемая выручка, руб',
              width: 140,
              valueFormatter: numberFormatter,
              headerTooltip:
                'Прогнозируемая выручка от заказов (сумма заказов, руб * на процент выкупа) за выбранный период.',
            },
            {
              field: 'sales_report.drr',
              name: 'drr',
              headerName: 'TACoO, % от суммы заказов',
              valueFormatter: numberFormatter,
              headerTooltip:
                'TACoO (Total Advertising Cost of Order - общая стоимость заказа на рекламу). Расходы на рекламу по отношению к общей сумме заказов, за выбранный период. (считаем формулой "(расходы на рекламу в руб / общий объем заказов в руб) х 100%)',
              width: 140,
            },
          ],
        },
      ];
      if (savedSorting) {
        const parsedSorting = JSON.parse(savedSorting);
        this.columns = this.getSortColumns(this.columns, parsedSorting);
      }
    },
    getSortColumns(columns, parsedSorting) {
      return columns.reduce((acc, column) => {
        if (column.children) {
          column.children = this.getSortColumns(column.children, parsedSorting);
          return [...acc, column];
        }
        return column.field === parsedSorting.order_field
          ? [
              ...acc,
              {
                ...column,
                sort: parsedSorting.order_type,
              },
            ]
          : [...acc, column];
      }, []);
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.generateTableColumns();
      const savedFilters = localStorage.getItem(`productsAdvertising-filters`);
      if (savedFilters) {
        setTimeout(() => {
          this.gridApi.setFilterModel(JSON.parse(savedFilters));
        }, 0);
      }
      setTimeout(() => {
        this.getTableCache({
          key: 'report-1',
        });
      }, 100);
    },
  },
};
</script>
<style lang=""></style>
