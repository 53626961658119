<template>
  <div class="manual-cell" :class="{ 'manual-cell_pointer': isNotes }">
    <p>{{ value }}</p>
    <svg
      v-if="!rowPinned && note"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.3125 0.875C6.24119 0.877275 4.25538 1.70111 2.79074 3.16574C1.32611 4.63038 0.502275 6.61619 0.5 8.6875V15.2758C0.500413 15.6003 0.629526 15.9115 0.859022 16.141C1.08852 16.3705 1.39966 16.4996 1.72422 16.5H8.3125C10.3845 16.5 12.3716 15.6769 13.8368 14.2118C15.3019 12.7466 16.125 10.7595 16.125 8.6875C16.125 6.6155 15.3019 4.62836 13.8368 3.16323C12.3716 1.6981 10.3845 0.875 8.3125 0.875ZM4.875 9.9375C4.68958 9.9375 4.50832 9.88252 4.35415 9.7795C4.19998 9.67649 4.07982 9.53007 4.00886 9.35876C3.93791 9.18746 3.91934 8.99896 3.95551 8.8171C3.99169 8.63525 4.08098 8.4682 4.21209 8.33709C4.3432 8.20598 4.51025 8.11669 4.6921 8.08051C4.87396 8.04434 5.06246 8.06291 5.23377 8.13386C5.40507 8.20482 5.55149 8.32498 5.6545 8.47915C5.75752 8.63332 5.8125 8.81458 5.8125 9C5.8125 9.24864 5.71373 9.4871 5.53791 9.66291C5.3621 9.83873 5.12364 9.9375 4.875 9.9375ZM8.3125 9.9375C8.12708 9.9375 7.94582 9.88252 7.79165 9.7795C7.63748 9.67649 7.51732 9.53007 7.44636 9.35876C7.37541 9.18746 7.35684 8.99896 7.39301 8.8171C7.42919 8.63525 7.51848 8.4682 7.64959 8.33709C7.7807 8.20598 7.94775 8.11669 8.1296 8.08051C8.31146 8.04434 8.49996 8.06291 8.67126 8.13386C8.84257 8.20482 8.98899 8.32498 9.092 8.47915C9.19502 8.63332 9.25 8.81458 9.25 9C9.25 9.24864 9.15123 9.4871 8.97541 9.66291C8.7996 9.83873 8.56114 9.9375 8.3125 9.9375ZM11.75 9.9375C11.5646 9.9375 11.3833 9.88252 11.2292 9.7795C11.075 9.67649 10.9548 9.53007 10.8839 9.35876C10.8129 9.18746 10.7943 8.99896 10.8305 8.8171C10.8667 8.63525 10.956 8.4682 11.0871 8.33709C11.2182 8.20598 11.3852 8.11669 11.5671 8.08051C11.749 8.04434 11.9375 8.06291 12.1088 8.13386C12.2801 8.20482 12.4265 8.32498 12.5295 8.47915C12.6325 8.63332 12.6875 8.81458 12.6875 9C12.6875 9.24864 12.5887 9.4871 12.4129 9.66291C12.2371 9.83873 11.9986 9.9375 11.75 9.9375Z"
        fill="#481173"
      />
    </svg>
    <svg
      v-else-if="isNotes"
      class="note-icon"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.3125 0.875C6.24119 0.877275 4.25538 1.70111 2.79074 3.16574C1.32611 4.63038 0.502275 6.61619 0.5 8.6875V15.2758C0.500413 15.6003 0.629526 15.9115 0.859022 16.141C1.08852 16.3705 1.39966 16.4996 1.72422 16.5H8.3125C10.3845 16.5 12.3716 15.6769 13.8368 14.2118C15.3019 12.7466 16.125 10.7595 16.125 8.6875C16.125 6.6155 15.3019 4.62836 13.8368 3.16323C12.3716 1.6981 10.3845 0.875 8.3125 0.875ZM4.875 9.9375C4.68958 9.9375 4.50832 9.88252 4.35415 9.7795C4.19998 9.67649 4.07982 9.53007 4.00886 9.35876C3.93791 9.18746 3.91934 8.99896 3.95551 8.8171C3.99169 8.63525 4.08098 8.4682 4.21209 8.33709C4.3432 8.20598 4.51025 8.11669 4.6921 8.08051C4.87396 8.04434 5.06246 8.06291 5.23377 8.13386C5.40507 8.20482 5.55149 8.32498 5.6545 8.47915C5.75752 8.63332 5.8125 8.81458 5.8125 9C5.8125 9.24864 5.71373 9.4871 5.53791 9.66291C5.3621 9.83873 5.12364 9.9375 4.875 9.9375ZM8.3125 9.9375C8.12708 9.9375 7.94582 9.88252 7.79165 9.7795C7.63748 9.67649 7.51732 9.53007 7.44636 9.35876C7.37541 9.18746 7.35684 8.99896 7.39301 8.8171C7.42919 8.63525 7.51848 8.4682 7.64959 8.33709C7.7807 8.20598 7.94775 8.11669 8.1296 8.08051C8.31146 8.04434 8.49996 8.06291 8.67126 8.13386C8.84257 8.20482 8.98899 8.32498 9.092 8.47915C9.19502 8.63332 9.25 8.81458 9.25 9C9.25 9.24864 9.15123 9.4871 8.97541 9.66291C8.7996 9.83873 8.56114 9.9375 8.3125 9.9375ZM11.75 9.9375C11.5646 9.9375 11.3833 9.88252 11.2292 9.7795C11.075 9.67649 10.9548 9.53007 10.8839 9.35876C10.8129 9.18746 10.7943 8.99896 10.8305 8.8171C10.8667 8.63525 10.956 8.4682 11.0871 8.33709C11.2182 8.20598 11.3852 8.11669 11.5671 8.08051C11.749 8.04434 11.9375 8.06291 12.1088 8.13386C12.2801 8.20482 12.4265 8.32498 12.5295 8.47915C12.6325 8.63332 12.6875 8.81458 12.6875 9C12.6875 9.24864 12.5887 9.4871 12.4129 9.66291C12.2371 9.83873 11.9986 9.9375 11.75 9.9375Z"
        fill="#481173"
      />
    </svg>
  </div>
</template>

<script>
import WarnIcon from '@/views/reports/report-6/components/icons/WarnIcon.vue';
import PencilIcon from '@/views/reports/report-6/components/icons/PencilIcon.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'PriceCell',
  components: { PencilIcon, WarnIcon },
  data() {
    return {
      value: '',
      note: null,
    };
  },
  methods: {
    change() {
      this.params.openModal(this.params, event);
    },
    getNestedValue(obj, path) {
      return path.split('.').reduce((acc, key) => acc && acc[key], obj);
    },
    setNote() {
      this.note = this.notes.find(
        (el) =>
          el.product === this.params.data.id &&
          this.params.colDef['field'] === el.field
      );
    },
  },
  watch: {
    'params.data': {
      handler() {
        this.value = this.getNestedValue(
          this.params.data,
          this.params.colDef['field']
        );
        this.setNote();
      },
      deep: true,
      immediate: true,
    },
    notes: {
      handler() {
        this.setNote();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters('report5', ['notes', 'isNotes']),
    rowPinned() {
      return this.params.node.rowPinned === 'bottom';
    },
  },
};
</script>

<style scoped>
.manual-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: relative;

    text-align: left;

    & .note-icon {
        display: none;
    }

    &:hover .note-icon{
        display: inline;
    }
}

.manual-cell_pointer{
    cursor: pointer;
}
</style>
