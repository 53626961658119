import dayjs from 'dayjs';

const ReportDateRangeKey = 'reports-date-ranges';

export function saveReportDateRange(dateRange) {
  let range = getReportDateRange();
  if (range) {
    Object.assign(range, dateRange);
  } else {
    range = dateRange;
  }
  const item = {
    value: range,
    expiry: dayjs().format('YYYY-MM-DD'),
  };
  localStorage.setItem(ReportDateRangeKey, JSON.stringify(item));
}

export function getReportDateRange() {
  const itemStr = localStorage.getItem(ReportDateRangeKey);
  if (!itemStr) {
    return null;
  }

  try {
    const item = JSON.parse(itemStr);
    if (dayjs().format('YYYY-MM-DD') !== item.expiry) {
      saveReportDateRange({
        start_date: dayjs(item.value.start_date)
          .add(1, 'days')
          .format('YYYY-MM-DD'),
        end_date: dayjs(item.value.end_date)
          .add(1, 'days')
          .format('YYYY-MM-DD'),
      });
      return getReportDateRange();
    }
    return item.value;
  } catch (e) {
    localStorage.removeItem(ReportDateRangeKey);
    return null;
  }
}
