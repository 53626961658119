import moment from 'moment';

moment.locale('ru');

const getEmptyDate = (date_interval, options) => {
  return {
    consumption_sum: 0,
    discount_price: 0,
    ordered_in_pieces: 0,
    ordered_in_rubles: 0,
    redeemed: 0,
    to_transfer: 0,
    buyout_percent: 0,
    spp_percent: 0,
    predicted_tacos: 0,
    date_interval: mapDate(date_interval, options),
  };
};

const getEmptyTotalDate = (date_interval, options) => {
  return {
    consumption_sum: 0,
    discount_price: 0,
    ordered_in_pieces: 0,
    ordered_in_rubles: 0,
    redeemed: 0,
    to_transfer: 0,
    buyout_percent: 0,
    spp_percent: 0,
    predicted_tacos: 0,
    date_interval: mapDate(date_interval, options),
  };
};

const mapDate = (date, options) => {
  if (options.groupBy === 'days') {
    return moment(date).format('DD.MM');
  }
  if (options.groupBy === 'weeks') {
    const startOfWeek = moment(date, 'YYYY-WW').startOf('week');
    const endOfWeek = moment(date, 'YYYY-WW').endOf('week');
    const startDate = moment(startOfWeek).isBefore(options.from)
      ? moment(options.from).format('DD.MM')
      : moment(startOfWeek).format('DD.MM');
    const endDate = moment(endOfWeek).isAfter(options.to)
      ? moment(options.to).format('DD.MM')
      : moment(endOfWeek).format('DD.MM');
    return `${startDate} - ${endDate}`;
  }
  if (options.groupBy === 'months') {
    return moment(date, 'YYYY-M').startOf('month').format('MMM');
  }
  return moment(date, 'YYYY').startOf('year').format('YYYY') + ' год';
};

const getMappedValue = (value) => {
  return parseInt(100 * value) / 100;
};

const mapReport = (report, options) => {
  return {
    consumption_sum: getMappedValue(report.consumption_sum),
    discount_price: getMappedValue(report.discount_price),
    ordered_in_pieces: getMappedValue(report.ordered_in_pieces),
    ordered_in_rubles: getMappedValue(report.ordered_in_rubles),
    redeemed: getMappedValue(report.redeemed),
    to_transfer: getMappedValue(report.to_transfer),
    buyout_percent: getMappedValue(report.buyout_percent),
    spp_percent: getMappedValue(report.spp_percent),
    predicted_tacos: getMappedValue(report.predicted_tacos),
    date_interval: options ? mapDate(report.date_interval, options) : undefined,
  };
};

const mapProduct = (product) => {
  return {
    ...product,
    total_consumptions: getMappedValue(product.total_consumptions),
    total_in_pieces: getMappedValue(product.total_in_pieces),
    total_in_rubles: getMappedValue(product.total_in_rubles),
    total_prices: getMappedValue(product.total_prices),
    total_redeemed: getMappedValue(product.total_redeemed),
    total_to_transfer: getMappedValue(product.total_to_transfer),
  };
};

const getDatesList = ({ from, to, groupBy }) => {
  let dates = [];
  const formatTypes = {
    days: 'YYYY-MM-DD',
    weeks: 'YYYY-W',
    months: 'YYYY-M',
    years: 'YYYY',
  };
  let currentDate = moment(from, 'YYYY-MM-DD');
  const stopDate = moment(to, 'YYYY-MM-DD');
  while (moment(currentDate).isSameOrBefore(stopDate, groupBy)) {
    dates.push(moment(currentDate).format(formatTypes[groupBy]));
    currentDate = moment(currentDate).add(1, groupBy);
  }
  return dates;
};

export const mapProducts = (products, options) => {
  const datesList = getDatesList(options);
  return products.map((product) => {
    const mappedReports = product.reports.reduce((acc, report) => {
      return {
        ...acc,
        [report.date_interval]: mapReport(report, options),
      };
    }, {});
    const fullReports = datesList.reduce((acc, date) => {
      return {
        ...acc,
        [date]: mappedReports[date]
          ? mappedReports[date]
          : getEmptyDate(date, options),
      };
    }, {});

    return {
      ...mapProduct(product),
      reports: fullReports,
    };
  });
};

export const mapTotals = (totals, options) => {
  const datesList = getDatesList(options);
  return {
    products_totals: mapProduct(totals.products_totals),
    reports_totals: datesList.reduce((acc, date) => {
      const mappedReport = mapReport(
        totals.reports_totals[date] ?? getEmptyDate(date, options)
      );
      return {
        ...acc,
        [date]: mappedReport,
      };
    }, {}),
  };
};
