<template>
  <div style="overflow-x: auto" v-if="isShow">
    <table bordered class="mt-4 product-statistic-table_total_data mb-1">
      <thead>
        <tr>
          <th
            style="
              width: 160px;
              background: #fcf8ff !important;
              z-index: 10;
              position: sticky;
            "
            class="sticky-col text-left pl-2"
          >
            Дата
          </th>
          <th
            style="background: #fcf8ff !important"
            v-for="(col, i) in renderGroup('header', data)"
            :key="i"
          >
            {{ renderDay(col) }}
          </th>
        </tr>
        <tr>
          <th class="sticky-col" style="border: 1px solid transparent">
            <div v-if="isTotal">
              <p class="product-statistic-table_total_data__advert-name">
                Итог
              </p>
            </div>
            <a
              v-else
              class="product-statistic-table_total_data__advert-name"
              :href="`/reports/statistic-advertising/${data.campaign_id}`"
            >
              {{ data.campaign_name }} {{ data.campaign_id }}
            </a>
          </th>
          <th :colspan="3" class="th_limit">
            <span class="th_limit__text">{{ renderLimit }}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in renderPros()" :key="index">
          <td class="sticky-col">
            <div class="product-statistic-tooltip">
              <span class="product-statistic-table_total_data__item-label">
                {{ item.label }}
              </span>
              <span class="tooltip-text">{{ item.tooltip }}</span>
            </div>
          </td>
          <td
            v-for="(row, i) in Object.entries(renderGroup('body', data))"
            :key="i"
          >
            <span class="product-statistic-table_total_data__item">
              <span v-if="item.key === 'bid'">
                {{ row[1].bid }}
              </span>
              <span v-else>
                <span v-if="['seller_price'].includes(item.key)">
                  {{
                    renderPrice(renderValue(item.key, row[1][item.key] / 100))
                  }}
                </span>
                <span v-else>
                  <span v-if="item.key === 'discount_price'">
                    {{ renderPrice(renderValue(null, row[1].discount_price)) }}
                  </span>
                  <span v-if="item.key === 'cpc' || item.key === 'drr'">{{
                    renderValue(item.key, row[1][item.key])
                  }}</span>
                  <span
                    v-if="!['cpc', 'discount_price', 'drr'].includes(item.key)"
                  >
                    {{
                      row[item.key] === 'ctr_percent'
                        ? renderValue(item.key, row[1][item.key])
                        : renderPrice(renderValue(item.key, row[1][item.key]))
                    }}
                  </span>
                </span>
              </span>
              <span
                style="margin-left: 5px"
                v-if="i > 0"
                v-html="
                  getRowGrowthIcon(renderGroup('body', data), i, item.key)
                "
              ></span>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { renderTextFromItem } from '@/utils/text';
import { renderPrice } from '@/utils/prices';
import moment from 'moment';

export default {
  name: 'totalTable',
  props: {
    data: {},
    activeGroup: {
      type: String,
    },
    isTotal: {
      type: Boolean,
      required: false,
      default: false,
    },
    limit: {
      type: Number,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      moment: moment,
      isShow: false,
      propsGeneral: [
        { label: 'Расход по РК, руб', key: 'consumption' },
        { label: 'CPM на WB, руб', key: 'bid' },
        // { label: "Рекламное место по кампании", key: "slot" },
        { label: 'CPM факт по кампании, руб', key: 'cpm_rub', text: 'руб' },
        { label: 'Просмотры, шт', key: 'views' },
        { label: 'Клики, шт', key: 'clicks' },
        { label: 'CTR %', key: 'ctr_percent' },
        { label: 'CPC, руб', key: 'cpc' },
        { label: 'CPL, руб', key: 'cost_cart_rub', text: 'руб' },
        { label: 'CPO, руб', key: 'cost_order_rub', text: 'руб' },
        {
          label: 'Добавление в корзину, шт',
          key: 'added_cart_pcs',
          text: 'руб',
        },
        {
          label: 'Оформление заказа по рекламе, шт',
          key: 'placing_order_pcs',
          text: 'руб',
        },
      ],
      propsTotals: [
        {
          label: 'Цена, руб',
          key: 'discount_price',
          tooltip:
            'Цена до скидок, выставляемая продавцом/менеджером на артикул товара.',
        },
        {
          label: 'Цена с СПП, руб',
          key: 'seller_price',
          tooltip:
            'Расчёт цены после СПП к цене после скидки продавца от базовой.',
        },
        {
          label: 'Размер СПП, %',
          key: 'spp_percent',
          tooltip:
            'Расчёт текущей величины СПП (обновляется три раза в сутки).',
        },
        {
          label: 'Цена после WB кошелька, руб',
          key: 'price_with_wallet',
          tooltip:
            'Расчёт конечной цены для покупателя с вычетом скидки кошелька из цены "после СПП". Использован уровень скидки срендестатист. покупателя. (= чек покупателя) ',
        },
        {
          label: 'Общий расход по всем РК, руб',
          key: 'consumption',
          tooltip:
            'Суммарный расход денежных средств на все виды рекламных кампаний за выбранный период относительно артикула. (из рекламной статистики ВБ)',
        },
        {
          label: 'Просмотры общ, шт',
          key: 'views',
          tooltip:
            'Количество (раз) показов/просмотров товара в рекламных кампаниях за анализируемый период.',
        },
        {
          label: 'Клики общ, шт',
          key: 'clicks',
          tooltip:
            'Количество кликов (переходов клиента в карточку товара) в рекламных кампаниях за анализируемый период.',
        },
        {
          label: 'CPM факт, руб',
          key: 'cpm_rub',
          tooltip:
            'СPM (Cost Per Mille или Cost Per Millenium) — стоимость тысячи показов рекламного объявления. Фактическая ставка в рекламной кампании за выбранный период. (считаем по формуле "количество показов в шт/ 1000)',
        },
        {
          label: 'Добавления в корзину, шт',
          key: 'added_cart_pcs',
          tooltip:
            'Количество добавленых товаров в корзину по рекламным кампаниям за анализируемый период. (из рекламной статистики ВБ)',
        },
        {
          label: 'Заказы по РК, шт',
          key: 'placing_order_pcs',
          tooltip:
            'Количество заказанных товаров по рекламным кампаниям за анализируемый период. (из рекламной статистики ВБ)',
        },
        {
          label: 'Заказы по отчету ВБ, шт',
          key: 'ordered_in_pieces',
          tooltip:
            'Количество заказанных товаров в штуках всего (органика + реклама) за анализируемый период. *из воронки продаж ВБ',
        },
        {
          label: 'Заказы по отчету ВБ, руб',
          key: 'ordered_in_rubles',
          text: 'руб',
          tooltip:
            'Сумма заказанных товаров в рублях всего (органика + реклама) за анализируемый период. *из воронки продаж ВБ',
        },
        {
          label: 'Процент выкупа, %',
          key: 'buyout_percent',
          tooltip:
            'Фактический % выкупа из воронки продаж ВБ, но тянем за период "две недели" за минусом одной предыдущей недели (периода атрибуции), т.о. количество завершённых заказов ~90% и данные точнее.',
        },
        {
          label: 'Прогнозируемая выручка, руб',
          key: 'profit_forecast',
          tooltip:
            'Расчёт прогнозируемой выручке после периода атрибуции из заказов за текущий день. Формула: сумма заказов за выбранный период * процент выкупа.',
        },
        {
          label: 'Средний чек, руб',
          key: 'order_price',
          tooltip:
            'Расчёт среднего чека заказа по цене до СПП. Формула: сумма заказов (каждый стянут по фактической цене в моменте) разделённая на кол-во заказов за анализируемый период.',
        },
        {
          label: 'Продажи по отчету ВБ, шт',
          key: 'redeemed',
          tooltip:
            'Количество проданных товаров в штуках всего (органика + реклама) за анализируемый период. *из воронки продаж ВБ',
        },
        {
          label: 'Продажи по отчету ВБ, руб',
          key: 'to_transfer',
          text: 'руб',
          tooltip:
            'Сумма проданных товаров в рублях всего (органика + реклама) за анализируемый период. *из воронки продаж ВБ',
        },
        // { label: "Породажи фин. отчет, шт", key: "redeemed_pcs" },
        // { label: "Продажи фин. отчет, руб", key: "redeemed_rub", text: 'руб' },
        {
          label: 'CPL Стоимость корзины, руб (средн)',
          key: 'cost_cart_rub',
          text: 'руб',
          tooltip:
            'CPL («Cost per lead» - цена за лид). Стоимость одного добавления в корзину относительно артикула по всем рекламным кампаниям за выбранный период.',
        },
        {
          label: 'CPO Стоимость заказа, руб (средн)',
          key: 'cost_order_rub',
          text: 'руб',
          tooltip:
            '"CPO («Cost per Order» - стоимость оформленного заказа). Стоимость одного оформленного заказа по всем рекламным кампаниям относительно артикула за выбранный \n' +
            'период. *формула ""расход, руб / на рекламный заказ шт"',
        },
        {
          label: 'CPS прогноз, руб (средн)',
          key: 'projected_сps',
          tooltip:
            'прогнозируемы CPS. «Cost per Sale» - стоимость одной продажи с текущих татальных заказов (рекламные + органические). *формула расход на рекламу, руб / на количество заказов * процент выкупа',
        },
        // { label: "CPS Стоимость продажи, руб (средн)", key: "cps" },
        {
          label: 'TACoO от суммы заказов, %',
          key: 'drr',
          tooltip:
            'TACoO (Total Advertising Cost of Order - общая стоимость заказа на рекламу). Расходы на рекламу по отношению к общей сумме заказов, за выбранный период. *формула "(расходы на рекламу в руб / общий объем заказов в руб) х 100',
        },
        {
          label: 'Прогнозируемый TACoS, %',
          key: 'tacos',
          tooltip:
            'TACoS (Total Advertising Cost of Order - общая стоимость заказа на рекламу). Расходы на рекламу по отношению к общей сумме заказов, за выбранный период. *формула "(расходы на рекламу в руб / общий объем заказов в руб) х 100',
        },
        {
          label: 'CTR средний по артикулу, %',
          key: 'ctr_percent',
          tooltip:
            'CTR (Click-Through Rate) - соотношение количества кликов к показам в процентах. * формула"(количество кликов / количество показов) х 100',
        },
        {
          label: 'Остаток товара, шт',
          key: 'stock',
          tooltip:
            'Сумма остатков товара на складах ФБО. *только текущий остаткок, без учёта "в пути"',
        },
      ],
    };
  },

  computed: {
    renderLimit() {
      if (typeof this.limit === 'number') {
        return `Лимита осталось меньше чем на ${this.limit} часа`;
      }

      return '';
    },
  },

  methods: {
    renderPrice,
    renderTextFromItem,

    renderPros() {
      if (!this.isTotal) {
        return this.propsGeneral;
      } else {
        return this.propsTotals;
      }
    },

    renderValue(key, value) {
      if (Number(value) === 0) return value;

      if (['cpm_rub'].includes(key)) {
        if (!isNaN(Number(value))) return Math.ceil(value.toFixed(1));
      }

      if (
        ['drr', 'cpc', 'tacos', 'spp_percent', 'buyout_percent'].includes(key)
      ) {
        return Number(value).toFixed(2);
      }

      if (['ctr_percent'].includes(key)) {
        if (!isNaN(Number(value))) {
          return Number(value).toFixed(2);
        }
      }

      if (['cost_cart_rub'.includes(key)]) {
        try {
          if (!isNaN(Number(value))) {
            return Math.ceil(value.toFixed(2));
          }
        } catch (err) {
          return value;
        }
      }

      return value;
    },

    formatDate(dateString) {
      const daysOfWeek = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
      const date = new Date(dateString);
      const dayOfWeek = daysOfWeek[date.getDay()];

      return `${dateString} ${dayOfWeek}`;
    },

    renderDay(item) {
      switch (this.activeGroup) {
        case 'days':
          return moment(item.day).format('DD.MM.YYYY');

        case 'weeks':
          const start = item.days[0];
          const end = item.days[item.days.length - 1];
          return `${start} - ${end}`;

        case 'months':
          const listMonths = [
            'Январь',
            'Февраль',
            'Март',
            'Апрель',
            'Май',
            'Июнь',
            'Июль',
            'Август',
            'Сентябрь',
            'Октябрь',
            'Ноябрь',
            'Декабрь',
          ];
          const str = listMonths[item.month - 1];
          return `${str} ${item.year}`;

        case 'years':
          return item.year;

        default:
          return '';
      }
    },

    renderGroup(type, advert) {
      switch (this.activeGroup) {
        case 'days':
          if (this.isTotal) {
            return this.data.days;
          } else return advert.days;

        case 'weeks':
          if (this.isTotal) return this.data.weeks;
          else return advert.weeks;

        case 'months':
          if (this.isTotal) return this.data.months;
          else return advert.months;

        case 'years':
          if (this.isTotal) return this.data.years;
          else return advert.years;

        default:
          return [];
      }
    },
    getRowGrowthIcon(data, index, key) {
      const prevRowKey = Object.entries(data)[index - 1][1][key];
      const curRowKey = Object.entries(data)[index][1][key];
      const downGreen =
        '<svg width="9" height="11" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M8.60644 6.64797L5.23144 10.023C5.17919 10.0754 5.11709 10.117 5.04872 10.1454C4.98034 10.1738 4.90704 10.1884 4.83301 10.1884C4.75897 10.1884 4.68567 10.1738 4.6173 10.1454C4.54892 10.117 4.48683 10.0754 4.43457 10.023L1.05957 6.64797C0.953897 6.5423 0.894531 6.39897 0.894531 6.24953C0.894531 6.10009 0.953897 5.95677 1.05957 5.85109C1.16524 5.74542 1.30856 5.68606 1.45801 5.68606C1.60745 5.68606 1.75077 5.74542 1.85644 5.85109L4.27098 8.26562V1.375C4.27098 1.22582 4.33024 1.08274 4.43573 0.977252C4.54122 0.871763 4.68429 0.8125 4.83348 0.8125C4.98266 0.8125 5.12573 0.871763 5.23122 0.977252C5.33671 1.08274 5.39598 1.22582 5.39598 1.375V8.26562L7.81051 5.85062C7.91618 5.74495 8.0595 5.68559 8.20894 5.68559C8.35839 5.68559 8.50171 5.74495 8.60738 5.85062C8.71305 5.9563 8.77242 6.09962 8.77242 6.24906C8.77242 6.39851 8.71305 6.54183 8.60738 6.6475L8.60644 6.64797Z" fill="#459A48"/>\n' +
        '</svg>\n';
      const downRed =
        '<svg width="9" height="11" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M8.44043 6.64797L5.06543 10.023C5.01317 10.0754 4.95107 10.117 4.8827 10.1454C4.81433 10.1738 4.74102 10.1884 4.66699 10.1884C4.59296 10.1884 4.51965 10.1738 4.45128 10.1454C4.38291 10.117 4.32081 10.0754 4.26855 10.023L0.893554 6.64797C0.787882 6.5423 0.728516 6.39897 0.728516 6.24953C0.728516 6.10009 0.787882 5.95677 0.893554 5.85109C0.999226 5.74542 1.14255 5.68606 1.29199 5.68606C1.44143 5.68606 1.58476 5.74542 1.69043 5.85109L4.10496 8.26562V1.375C4.10496 1.22582 4.16422 1.08274 4.26971 0.977252C4.3752 0.871763 4.51828 0.8125 4.66746 0.8125C4.81664 0.8125 4.95972 0.871763 5.06521 0.977252C5.1707 1.08274 5.22996 1.22582 5.22996 1.375V8.26562L7.64449 5.85062C7.75016 5.74495 7.89348 5.68559 8.04293 5.68559C8.19237 5.68559 8.33569 5.74495 8.44137 5.85062C8.54704 5.9563 8.6064 6.09962 8.6064 6.24906C8.6064 6.39851 8.54704 6.54183 8.44137 6.6475L8.44043 6.64797Z" fill="#F44336"/>\n' +
        '</svg>\n';
      const upRed =
        '<svg width="9" height="11" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M8.27344 5.14787C8.22118 5.20031 8.15908 5.24191 8.09071 5.2703C8.02234 5.2987 7.94903 5.31331 7.875 5.31331C7.80097 5.31331 7.72766 5.2987 7.65929 5.2703C7.59092 5.24191 7.52882 5.20031 7.47656 5.14787L5.06297 2.73427V9.6249C5.06297 9.77408 5.0037 9.91716 4.89821 10.0226C4.79273 10.1281 4.64965 10.1874 4.50047 10.1874C4.35128 10.1874 4.20821 10.1281 4.10272 10.0226C3.99723 9.91716 3.93797 9.77408 3.93797 9.6249V2.73427L1.52344 5.14787C1.41776 5.25354 1.27444 5.3129 1.125 5.3129C0.975556 5.3129 0.832234 5.25354 0.726562 5.14787C0.62089 5.04219 0.561523 4.89887 0.561523 4.74943C0.561523 4.59999 0.62089 4.45666 0.726562 4.35099L4.10156 0.97599C4.15382 0.92355 4.21592 0.881942 4.28429 0.853552C4.35266 0.825161 4.42597 0.810547 4.5 0.810547C4.57403 0.810547 4.64734 0.825161 4.71571 0.853552C4.78408 0.881942 4.84618 0.92355 4.89844 0.97599L8.27344 4.35099C8.32588 4.40325 8.36748 4.46535 8.39587 4.53372C8.42427 4.60209 8.43888 4.6754 8.43888 4.74943C8.43888 4.82346 8.42427 4.89677 8.39587 4.96514C8.36748 5.03351 8.32588 5.09561 8.27344 5.14787Z" fill="#F44336"/>\n' +
        '</svg>\n';
      const upGreen =
        '<svg width="9" height="11" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M8.10644 5.14787C8.05419 5.20031 7.99209 5.24191 7.92372 5.2703C7.85534 5.2987 7.78204 5.31331 7.70801 5.31331C7.63397 5.31331 7.56067 5.2987 7.4923 5.2703C7.42392 5.24191 7.36183 5.20031 7.30957 5.14787L4.89598 2.73427V9.6249C4.89598 9.77408 4.83671 9.91716 4.73122 10.0226C4.62573 10.1281 4.48266 10.1874 4.33348 10.1874C4.18429 10.1874 4.04122 10.1281 3.93573 10.0226C3.83024 9.91716 3.77098 9.77408 3.77098 9.6249V2.73427L1.35644 5.14787C1.25077 5.25354 1.10745 5.3129 0.958007 5.3129C0.808564 5.3129 0.665242 5.25354 0.55957 5.14787C0.453897 5.04219 0.394531 4.89887 0.394531 4.74943C0.394531 4.59999 0.453897 4.45666 0.55957 4.35099L3.93457 0.97599C3.98683 0.92355 4.04892 0.881942 4.1173 0.853552C4.18567 0.825161 4.25897 0.810547 4.33301 0.810547C4.40704 0.810547 4.48034 0.825161 4.54872 0.853552C4.61709 0.881942 4.67919 0.92355 4.73144 0.97599L8.10644 4.35099C8.15888 4.40325 8.20049 4.46535 8.22888 4.53372C8.25727 4.60209 8.27189 4.6754 8.27189 4.74943C8.27189 4.82346 8.25727 4.89677 8.22888 4.96514C8.20049 5.03351 8.15888 5.09561 8.10644 5.14787Z" fill="#459A48"/>\n' +
        '</svg>\n';
      const greenUpKeys = [
        'discount_price',
        'spp_percent',
        'views',
        'clicks',
        'added_cart_pcs',
        'ordered_in_pieces',
        'ordered_in_rubles',
        'buyout_percent',
        'profit_forecast',
        'redeemed',
        'to_transfer',
        'ctr_percent',
        'placing_order_pcs',
        'order_price',
        'price_with_wallet',
      ];
      if (curRowKey > prevRowKey) {
        if (greenUpKeys.includes(key)) {
          return upGreen;
        } else {
          return upRed;
        }
      } else if (curRowKey < prevRowKey) {
        if (greenUpKeys.includes(key)) {
          return downRed;
        } else {
          return downGreen;
        }
      }
    },
  },

  created() {
    this.isShow = true;
  },
};
</script>

<style lang="scss">
@import "../index.scss";
table.product-statistic-table_total_data {
  & thead {
    position: sticky;
    top: -1px;
    background-color: white;
    z-index: 2;

    & th {
      color: #908F90 !important;
      font-size: 12px !important;
      font-weight: 500 !important;
      box-shadow: none !important;

      &:first-child {
        border-right: 1px solid #F1F1F1 !important;
      }

      &.th_limit {
        background: #fff !important;
      }
    }
  }

  & tbody {
    & tr {
      & td {
        background: #F3F5F6 !important;
      }

      &:nth-child(odd) {
        & td {
          background: #fff !important;
        }
      }

      &:hover td {
        background: #E4F2FF !important;
      }
    }

    & td {
      padding: 1px 2px !important;
      box-shadow: none !important;
      color: #160027 !important;
      font-size: 10px;

      &:first-child {
        border-right: 1px solid #F1F1F1 !important;
      }

      & span {
        box-shadow: none !important;
      }
    }
  }
}

.product-statistic-table_total_data__advert-name {
  padding-left: 8px;
  justify-content: start !important;
}

.th_limit {
  border-right: none !important;

  &__text {
    color: #DC5247 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
  }
}

.productStatistic__tables {
  background: #FCF8FF;
}

.product-statistic-table_total_data__item {
  color: #160027 !important;
  display: flex;
  align-items: center;
}

.product-statistic-tooltip {
    position: relative;
    display: inline-block;
    cursor: help;
}

.product-statistic-tooltip .tooltip-text {
    visibility: hidden;
    background-color: white;
    color: #868686;
    text-align: center;
    padding: 10px;
    border: 1px solid #ddd;
    font-size: 14px;
    position: absolute;
    z-index: 1000;
    top: 10px;
    left: 101%;
    width: max-content;
    max-width: 1000px;
    opacity: 0;
    transition: opacity 0.3s;
}

.product-statistic-tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}
</style>
