export const mutations = {
  REPORT5_SET_LOADING(state, payload) {
    state.loading = payload;
  },

  REPORT5_TO_INITIAL_VALUES(state) {
    state.items = [];
    state.totalsItems = {
      reports_totals: {},
      products_totals: {},
    };
  },

  REPORT5_SET_ITEMS(state, payload) {
    state.items = payload;
  },

  REPORT5_SET_NOTES(state, payload) {
    state.notes = payload;
  },
  REPORT5_SET_IS_NOTES(state, payload) {
    state.isNotes = payload;
  },
  REPORT5_SET_ADD_NOTE(state, payload) {
    const index = state.notes.findIndex((obj) => obj.id === payload.id);
    if (index !== -1) {
      state.notes[index] = payload;
    } else {
      state.notes.push(payload);
    }
  },
  REPORT5_SET_DELETE_NOTE(state, noteId) {
    state.notes = state.notes.filter((item) => item.id !== noteId);
  },
  REPORT5_SET_TOTAL_ITEMS(state, payload) {
    state.totalsItems = payload;
  },

  SET_OPTIONS(state, payload) {
    state.options = payload;
  },

  SET_FILTERS(state, payload) {
    state.filters = payload;
  },
  SET_SUITABLE_SUBSCRIPTION(state, payload) {
    state.hasSuitableSubscription = payload;
  },
};
