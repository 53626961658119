<template>
  <div>
    <div class="productStatistic">
      <div class="productStatistic__container">
        <div class="productStatistic-filter">
          <filterAndSort
            @dateFilterHandler="dateFilterHandler"
            :article="current_id"
            :hide-options="['image']"
            :disabled-options="['export']"
            :groups="groups"
            :onSelectGroup="onSelectGroup"
          ></filterAndSort>
        </div>
        <div class="product-statistic-info" v-show="!loading">
          <div class="product-statistic-info__name">
            <a>
              Анализ рекламной кампании {{ advName.campaign_id }}. <br />{{
                advName.campaign_name
              }}
            </a>
          </div>
          <div class="product-statistic-info__limit">
            {{ renderLimit(advName.limit_hours_left) }}
          </div>
        </div>

        <div v-if="isHasSubscribe">
          <dynamicChart
            v-if="data_chart"
            :data="data_chart"
            title="ДРР на кампанию от суммы заказов с кампании"
          >
          </dynamicChart>
        </div>

        <div v-if="isHasSubscribe">
          <b-skeleton-table
            :rows="5"
            v-if="loading"
            :columns="4"
            :table-props="{ bordered: true, striped: true }"
          ></b-skeleton-table>

          <div v-if="!loading">
            <div class="products">
              <div class="products__item">
                <div class="table-responsive">
                  <table
                    class="product-statistic-table table product-statistic-table-v2"
                  >
                    <thead>
                      <tr class="product_info">
                        <th
                          class="product_info__name sticky-col"
                          style="z-index: 10"
                        >
                          Дата
                        </th>
                        <th
                          v-for="item in renderGroup('totals')"
                          :key="item.id"
                          :colspan="totals.length"
                        >
                          {{ renderDay(item) }}
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr class="total-title">
                        <td class="total-title-text sticky-col">Итог</td>
                        <td :colspan="listDate.length"></td>
                      </tr>

                      <tr
                        v-for="totalOption in totalOptions"
                        :key="totalOption.prop"
                      >
                        <td
                          class="total-option sticky-col"
                          :class="{ bold: totalOption.isBold }"
                        >
                          {{ totalOption.label }}
                        </td>
                        <td
                          v-for="(totalResult, index) in renderGroup('totals')"
                          :key="index"
                        >
                          {{
                            renderOption(null, totalResult[totalOption.prop])
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div
              class="products-table"
              v-for="(product, index) in products"
              :key="`product-table-${product.nm_id}`"
            >
              <div class="table-responsive">
                <table
                  class="product-statistic-table table product-statistic-table-v2"
                >
                  <thead>
                    <tr class="product_info">
                      <th
                        class="product_info__name sticky-col"
                        style="z-index: 10"
                      >
                        Дата
                      </th>
                      <th
                        v-for="item in renderGroup('totals')"
                        :key="item.id"
                        :colspan="totals.length"
                      >
                        {{ renderDay(item) }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="product-statistic-table__tr-photo">
                      <td class="sticky-col" style="z-index: 9">
                        <THProduct :product="{ product }" />
                      </td>
                      <td :colspan="product.stat && product.stats.length"></td>
                    </tr>
                    <tr v-for="productOption in productOptions">
                      <td class="sticky-col">{{ productOption.label }}</td>
                      <td v-for="item in renderGroup('product', product)">
                        <span v-if="productOption.prop === 'ctr'">
                          {{
                            renderOption(
                              productOption.prop,
                              item[productOption.prop]
                            )
                          }}
                        </span>
                        <span v-else>
                          {{
                            renderOption(
                              productOption.prop,
                              item[productOption.prop]
                            )
                          }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <PartEmpty v-else />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import filterAndSort from './components/filterAndSort.vue';
import totalTable from './components/totalTable.vue';
import chart from './components/dynamicChart';
import moment from 'moment';
import StatisticAdvertising from '@/api/statistic-advertising';
import WeekBlock from '@/views/reports/productsStatistic/components/WeekBlock.vue';
import THProduct from '@/views/reports/productsStatistic/components/THProduct.vue';
import ReportsGroupBtnMenu from '@/components/reports-groups/ReportsGroupBtnMenu.vue';
import dynamicChart from '@/views/reports/productsStatistic/components/dynamicChart.vue';
import PartEmpty from '@/components/empty/PartEmpty.vue';
import { renderTextFromItem } from '@/utils/text';
import { renderPrice } from '@/utils/prices';

export default {
  name: 'index',
  components: {
    PartEmpty,
    dynamicChart,
    filterAndSort,
    totalTable,
    chart,
    WeekBlock,
    THProduct,
    ReportsGroupBtnMenu,
  },
  data() {
    return {
      newDirectCompetitor: '',
      isWatchCompetitor: true,
      indexProduct: null,
      current_id: null,
      loading: false,
      newKeyword: '',
      from: null,
      to: null,
      fromDefault: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      toDefault: moment().format('YYYY-MM-DD'),
      weeks: [1],
      listDate: [],
      products: [],
      productOptions: [
        { prop: 'total', label: 'Расход, руб', text: 'руб' },
        { prop: 'discount_price', label: 'Цена, руб', text: 'руб' },
        { prop: 'seller_price', label: 'Цена с СПП, руб ', text: 'руб' },
        { prop: 'views', label: 'Просмотры, шт' },
        { prop: 'clicks', label: 'Клики, шт' },
        { prop: 'ctr', label: 'CTR, %' },
        { prop: 'cpc', label: 'CPC, руб' },
        { prop: 'cpl', label: 'CPL, руб' },
        { prop: 'cpo', label: 'CPO, руб' },
        { prop: 'cart', label: 'Добавлений в корзину, шт' },
        { prop: 'orders', label: 'Оформлений заказа по рекламе, шт' },
        { prop: 'ordered_in_pieces', label: 'Заказы по отчету ВБ, шт' },
        {
          prop: 'ordered_in_rubles',
          label: 'Заказы по отчету ВБ, руб.',
          text: 'руб',
        },
        { prop: 'redeemed', label: 'Продажи по отчету ВБ, шт' },
        {
          prop: 'to_transfer',
          label: 'Продажи по отчету ВБ, руб',
          text: 'руб',
        },
        { prop: 'stock', label: 'Остатки товара', text: 'шт' },

        // {prop: 'redeemed_pcs', label: 'Продажи фин. отчет, шт', text: 'руб'},
        // {prop: 'redeemed_rub', label: 'Продажи фин. отчет, руб', text: 'руб'},
      ],
      totalOptions: [
        {
          label: 'Общий расход по артикулам, руб',
          prop: 'total',
          isMoney: true,
          color: 'red',
        },
        { label: 'Просмотры общ, шт', prop: 'views', isMoney: false },
        { label: 'Клики общ, шт', prop: 'clicks', isMoney: false },
        { label: 'CPM на WB, руб', prop: 'bid', isMoney: false },
        // {label: 'Рекламное место по кампании', prop: 'slot', isMoney: false},
        { label: 'CPM факт по кампании, руб', prop: 'cpm', isMoney: false },
        { label: 'Добавлений в корзину, шт', prop: 'cart', isMoney: false },
        { label: 'Заказы общ. по рекламе, шт', prop: 'orders', isMoney: false },
        {
          label: 'Заказы по отчету ВБ, шт',
          prop: 'ordered_in_pieces',
          isMoney: false,
        },
        {
          label: 'Заказы по отчету ВБ, руб',
          prop: 'ordered_in_rubles',
          isMoney: true,
        },
        { label: 'Продажи по отчету ВБ, шт', prop: 'redeemed', isMoney: false },
        {
          label: 'Продажи по отчету ВБ, руб ',
          prop: 'to_transfer',
          isMoney: true,
          text: 'руб',
        },
        // {label: 'Продажи фин. отчет, шт', prop: 'redeemed_pcs', isMoney: false},
        // {label: 'Продажи фин. отчет, руб', prop: 'redeemed_rub', isMoney: true},
        {
          label: 'CPL Стоимость корзины, руб (средн)',
          prop: 'cpl',
          isMoney: true,
          text: 'руб',
        },
        {
          label: 'CPO Стоимость заказа, руб (средн)',
          prop: 'cpo',
          isMoney: true,
          text: 'руб',
        },
        // {label: 'CPS Стоимость продажи, руб (средн)', prop: 'cps', isMoney: true, text: 'руб'},
        {
          label: 'TACoO от суммы заказов, % ',
          prop: 'drr',
          isMoney: false,
          text: '%',
        },
        { prop: 'stock', label: 'Остатки товара', text: 'шт' },
      ],
      totalOptionsChart: [
        {
          label: 'Общий расход по артикулам, руб',
          prop: 'total',
          isMoney: true,
          color: '#009900',
        },
        {
          label: 'Просмотры общ, шт',
          prop: 'views',
          isMoney: false,
          color: '#CC9966',
        },
        {
          label: 'Клики общ, шт',
          prop: 'clicks',
          isMoney: false,
          color: '#996633',
        },
        // {label: 'CPM на WB, руб', prop: 'bid', isMoney: false},
        {
          label: 'CPM факт по кампании, руб',
          prop: 'cpm',
          isMoney: false,
          color: '#66FF00',
        },
        {
          label: 'CPO Стоимость заказа, руб (средн)',
          prop: 'cpo',
          isMoney: true,
          text: 'руб',
          color: '#FF6600',
        },
        // {label: 'Добавлений в корзину, шт', prop: 'cart', isMoney: false},
        {
          label: 'Заказы общ. по рекламе, шт',
          prop: 'orders',
          isMoney: false,
          color: '#FF99CC',
        },
        {
          label: 'Заказы по отчету ВБ, шт',
          prop: 'ordered_in_pieces',
          isMoney: false,
          color: '#FF6666',
        },
        {
          label: 'Заказы по отчету ВБ, руб',
          prop: 'ordered_in_rubles',
          isMoney: true,
          color: '#CC0033',
        },
        {
          label: 'Продажи по отчету ВБ, шт',
          prop: 'redeemed',
          isMoney: false,
          color: '#9933CC',
        },
        {
          label: 'Продажи по отчету ВБ, руб ',
          prop: 'to_transfer',
          isMoney: true,
          text: 'руб',
          color: '#6600CC',
        },
        {
          prop: 'stock',
          label: 'Остатки товара, шт',
          text: 'шт',
          isHiddenDefault: true,
          color: '#0066FF',
        },
      ],
      totals: [],
      advName: {},
      tableHeight: 0,
      activeGroup: 'days',
      groups: [
        { key: 'days', name: 'Группировка по дням' },
        { key: 'weeks', name: 'Группировка по неделям' },
        { key: 'months', name: 'Группировка по месяцам' },
        { key: 'years', name: 'Группировка по годам' },
      ],
      data_chart: [],
    };
  },

  watch: {
    '$route.query.id': {
      handler(newVal, oldVal) {
        this.getReports();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(['statisticAdvertising', 'dynamic_chart', 'isHasSubscribe']),
  },
  methods: {
    ...mapActions([
      'getStatisticAdvertising',
      'postDirectCompetitor',
      'postKeyword',
      'updateDateRangeFromQueryParams',
      'deleteKeyword',
    ]),
    ...mapMutations(['SET_DATE']),
    renderTextFromItem,
    renderPrice,

    renderLimit(limit) {
      if (typeof limit === 'number') {
        return `Лимита хватит меньше чем на ${limit} часа`;
      }

      return limit;
    },

    getRandomColor() {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },

    setDataChart(totals) {
      this.data_chart = {
        labels: [],
        datasets: [],
      };

      this.data_chart.labels = totals.days.map((day) => {
        return moment(day.day).format('DD.MM.YYYY');
      });
      this.data_chart.datasets = this.totalOptionsChart.map((item) => {
        return {
          label: item.label,
          isMoney: item.isMoney || false,
          data: totals.days.map((obj) => {
            return obj[item.prop];
          }),
          isHiddenDefault: item.isHiddenDefault || false,
          backgroundColor: item.color,
        };
      });
    },

    renderDay(item) {
      switch (this.activeGroup) {
        case 'days':
          return moment(item.day).format('DD.MM.YYYY');

        case 'weeks':
          const start = item.days[0];
          const end = item.days[item.days.length - 1];

          return `${start} - ${end}`;

        case 'months':
          const listMonths = [
            'Январь',
            'Февраль',
            'Март',
            'Апрель',
            'Май',
            'Июнь',
            'Июль',
            'Август',
            'Сентябрь',
            'Октябрь',
            'Ноябрь',
            'Декабрь',
          ];
          const str = listMonths[item.month - 1];

          return `${str} ${item.year}`;

        case 'years':
          return item.year;

        default:
          return '';
      }
    },

    renderGroup(type, product) {
      switch (this.activeGroup) {
        case 'days':
          if (type === 'totals') return this.totals.days;
          else return product.days;

        case 'weeks':
          if (type === 'totals') return this.totals.weeks;
          else return product.weeks;

        case 'months':
          if (type === 'totals') return this.totals.months;
          else return product.months;

        case 'years':
          if (type === 'totals') return this.totals.years;
          else return product.years;

        default:
          return [];
      }
    },

    onSelectGroup(key) {
      this.activeGroup = key;
    },

    formatDate(dateString) {
      const daysOfWeek = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
      const date = new Date(dateString);
      const dayOfWeek = daysOfWeek[date.getDay()];

      return `${dateString} ${dayOfWeek}`;
    },

    renderOption(prop, value) {
      console.log(prop, value);
      if (Number(value) === 0) return 0;

      if (prop === 'seller_price') {
        console.log('seller_price condition', parseInt(value));
        const rub = parseInt(value) / 100;
        return rub.toFixed(1);
      }

      if (value !== null && value !== '') {
        if (!isNaN(Number(value))) {
          if (value % 1 !== 0) {
            if (['ctr', 'cpc'].includes(prop)) {
              const ctrValue = value.toFixed(2);

              if (ctrValue.toString().length < 2) {
                return ctrValue.toString() + '0';
              }

              return ctrValue;
            }

            return value.toFixed(1);
          }
        }
      }

      if (value && Number(value)) {
        return Number(value).toFixed(2);
      }

      return value;
    },

    dateFilterHandler(dates) {
      this.to = dates.to;
      this.from = dates.from;
      this.SET_DATE('dates');
      this.getReports();
    },

    async addDirectCompetitor() {
      if (this.newDirectCompetitor.length) {
        await this.postDirectCompetitor({
          article: this.current_id,
          competitor_nm_id: this.newDirectCompetitor,
        });
        this.newDirectCompetitor = '';
      }
    },

    async removeKeyword(payload) {
      if (payload) {
        await this.deleteKeyword({ id: payload.keyword_id });
      }
      this.getReports();
    },
    async removeCompetitor(id) {
      if (id) {
        await this.deleteCompetitor({ id: id });
      }
      this.getReports();
    },
    async addKeyword() {
      if (this.newKeyword.length) {
        await this.postKeyword({
          nm_id: this.current_id,
          keyword: this.newKeyword,
        });

        this.newKeyword = '';
        this.getReports();
      }
    },
    watchCompetitor() {
      this.isWatchCompetitor = !this.isWatchCompetitor;
    },
    async getReports() {
      try {
        this.loading = true;
        this.current_id = this.$route.params.id;

        this.updateDateRangeFromQueryParams(this.$route.query);

        let startDate = !this.from
          ? moment().subtract(8, 'days').format('YYYY-MM-DD')
          : this.from;
        let endDate = !this.to
          ? moment().subtract(1, 'days').format('YYYY-MM-DD')
          : this.to;

        const params = {
          start_date: startDate,
          end_date: endDate,
          advert: this.current_id,
        };
        for (let key of Object.keys(params)) {
          if (!params[key]) {
            delete params[key];
          }
        }

        const data = await StatisticAdvertising.get(params);
        this.products = data.data.data.products;
        this.totals = data.data.totals;
        this.advName = data.data.data;
        this.setDataChart(this.totals);

        this.$forceUpdate();
      } catch (err) {
        console.error(err);
        this.products = [];
      } finally {
        this.loading = false;
      }
    },

    resize() {
      this.tableHeight = 0;

      this.$nextTick(() => {
        if (document.getElementById('content')) {
          this.tableHeight =
            document.getElementById('content').clientHeight - 100;
        }
      });
    },
  },
  mounted() {
    if (this.statisticAdvertising) {
      this.indexProduct = this.statisticAdvertising[0].products[0];
    }

    this.resize();
    this.getReports();
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';

.productStatistic {
  &__header {
    height: 44px;
    border-top: 1px solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;
    display: flex;
    align-items: center;
    background: #fff;

    & > * {
      padding: 0 24px;
      text-align: center;
    }
  }

  & .product-statistic-table_total_data {
    margin-top: 0 !important;
  }

  & .product-statistic-table {
    & tr > th:first-child {
      width: 185px;
      min-width: 185px;
    }
  }

  & .th__limit {
    color: #dc5247;
    font-size: 16px;
    font-family: Raleway;
  }
}

.product-statistic-table {
  border: 1px solid #f1f1f1;

  & tbody {
    border-bottom: none !important;
  }

  & th,
  td {
    vertical-align: middle !important;
  }

  & th {
    min-width: 15ch;
    position: sticky;
    top: 0;

    &:not(:first-child) {
      width: 100px;
      min-width: 100px;
    }
  }

  & td {
    color: #908f90;
    font-size: 12px;
    text-align: center;

    &:first-child {
      color: #160027;
    }
  }

  & thead tr th:first-child,
  & tbody tr td:first-child {
    text-align: left;
  }

  & .tr_date {
    background: #fcf8ff !important;

    th {
      min-width: 140px;
      color: #908f90;
      font-size: 12px;
      font-weight: 500;
      background: #fcf8ff !important;

      &:not(:first-child) {
        border-right: none !important;
        box-shadow: none !important;
      }
    }
  }

  & .product_info {
    background: #fff;
  }

  & .limit {
    border-bottom: 1px solid #e7e6e6 !important;

    & .th__limit-product {
      box-shadow: inset 0 0 2px #f1f1f1 !important;
    }

    th {
      box-shadow: none !important;
    }
  }

  & .product_info {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: #fff;
    }

    & th {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 1px;
        background: #fff;
      }
    }

    &__name {
      text-align: left;
      color: #481173;
      font-size: 12px;
      font-weight: 600;
    }
  }

  & .total-title {
    &-text {
      color: #481173;
      font-size: 16px;
      font-style: normal;
      font-weight: 800;
    }

    & td {
      border-left: none;
      border-right: none !important;
      box-shadow: none !important;
    }
  }

  & .total-option {
    &.bold {
      font-weight: 600;
    }
  }
}

.productStatistic__container {
  width: 100%;
  overflow: hidden;
}

.limit_hours_left {
  text-align: left;
}

.company_name {
  font-size: 15px;
}

.th_limit {
  border-right: none !important;

  &__text {
    color: #dc5247 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
  }
}

.productStatistic__title {
  margin: 40px 0 20px;
  font-size: 24px;
  font-weight: 600;
}

.total-title-text {
  color: #481173 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}

.products {
  margin: 40px 0;
}

.products-table {
  margin-bottom: 40px;
}

.product-statistic-info {
  width: 100%;
  border-top: 1px solid #f1f1f1;
  padding: 12px 24px;
  display: grid;
  grid-template-columns: 350px calc(100% - 350px);
  background: #fff;

  &__name {
    color: #481173 !important;
    font-size: 12px !important;
    font-weight: 600 !important;
  }

  &__limit {
    color: #dc5247;
    font-size: 14px;
    font-weight: 500;
  }
}

.product-statistic-table-v2 {
  border: none;

  & thead {
    & th {
      border-bottom: 1px solid #f1f1f1 !important;
      color: #908f90 !important;
      font-size: 12px !important;
      font-weight: 500 !important;
      box-shadow: none !important;
      background: #fcf8ff;

      &:first-child {
        border-right: 1px solid #f1f1f1 !important;
      }
    }
  }

  & tbody {
    border-top: 2px solid #f1f1f1;

    & tr {
      & td {
        background: #F3F5F6 !important;
      }

      &:nth-child(odd) {
        & td {
          background: #fff !important;
        }
      }

      &:hover td {
        background: #E4F2FF !important;
      }
    }
  }

  & td {
    padding: 9px !important;
    box-shadow: none !important;
    color: #160027;
    font-size: 12px;

    &:first-child {
      border-right: 1px solid #f1f1f1 !important;
    }
  }

  & .product-statistic-table__tr-photo {
    & td {
      border-right: none !important;
      border-left: none !important;

      &:first-child {
        border-right: none !important;
      }
    }
  }
}
</style>
