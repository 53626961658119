<template>
  <div class="table">
    <NewModalSettings
      v-if="columns.length"
      pageName="report-5"
      :columns="columns"
      :showZeros="getShowZeros()"
      @setShowZeros="setShowZeros"
      @setColumns="setColumns"
      ref="settingsModal"
    />
    <ag-grid-vue
      class="ag-theme-alpine custom-grid"
      style="width: 100%; height: 100%"
      :columnDefs="columns"
      :rowData="items"
      :gridOptions="gridOptions"
      :defaultColDef="defaultColDef"
      :pinnedBottomRowData="getPinnedBottomRowData()"
      :tooltipShowDelay="0"
      @grid-ready="onGridReady"
      @filter-changed="onFilterChanged"
      enableCellTextSelection="true"
      @cellClicked="onCellClicked"
      @column-resized="saveColumnStateDebounced"
      @column-moved="saveColumnStateDebounced"
    ></ag-grid-vue>
    <NoteModal
      :is-visible="noteModal.ModalVisible"
      :productId="noteModal.ModalProduct"
      :field="noteModal.ModalField"
      :style="{
        top: noteModal.ModalPosition.top + 'px',
        left: noteModal.ModalPosition.left + 'px',
      }"
      @createNote="addNote"
      @closeModal="closeNoteModal"
    />
  </div>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue';
import { mapGetters, mapActions } from 'vuex';
import TableHeader from '@/components/ui/table/TableHeader.vue';
import TableFilterModal from '@/components/ui/table/TableFilterModal.vue';
import NewModalSettings from '@/components/ui/table/NewModalSettings.vue';
import ImgTooltip from '@/components/images/ImgTooltip.vue';
import TableImageComponent from '@/components/ui/table/TableImageComponent';
import {
  numberFormatter,
  percentFormatterBare,
  roundedNumberFormatter,
} from '@/helpers/numberFormatter';
import LinkCell from '@/components/ui/table/LinkCell.vue';
import NoteModal from '@/views/reports/report-5/components/modals/NoteModal.vue';
import NoteCell from '@/views/reports/report-5/components/cells/NoteCell.vue';
import RnpTableHeaderSettings from '@/components/ui/table/RnpTableHeaderSettings.vue';
import debounce from 'lodash/debounce';
export default {
  name: 'Table',
  props: ['items', 'totalsItems', 'notes'],
  components: {
    NoteModal,
    AgGridVue,
    agColumnHeader: TableHeader,
    TableFilterModal,
    NewModalSettings,
    RnpTableHeaderSettings,
    ImgTooltip,
    LinkCell,
    TableImageComponent,
    NoteCell,
  },
  data() {
    return {
      columns: [],
      gridOptions: {},
      defaultColDef: {
        resizable: true,
        width: 150,
        filter: false,
        sortable: true,
        unSortIcon: true,
        cellDataType: false,
        alwaysShowHorizontalScroll: true,
        alwaysShowVerticalScroll: true,
        headerComponentParams: {
          pageName: 'report5',
        },
      },
      gridApi: null,
      isNotes: false,
      noteModal: {
        ModalPosition: { top: 0, left: 0 },
        ModalVisible: false,
        ModalField: null,
        ModalProduct: null,
      },
    };
  },
  computed: {
    ...mapGetters('report5', ['options']),
    ...mapGetters('tableCache', ['tableCacheValue']),
  },
  watch: {
    tableCacheValue: {
      handler() {
        if (this.tableCacheValue) {
          this.gridApi.applyColumnState({
            state: JSON.parse(this.tableCacheValue),
            applyOrder: true,
          });
        }
      },
    },
  },
  created() {
    this.saveColumnStateDebounced = debounce(this.onColumnChanged, 1000);
  },
  methods: {
    ...mapActions('report5', [
      'getData',
      'setOptions',
      'createNote',
      'saveNotesModalStatus',
    ]),
    ...mapActions('tableCache', ['setTableCache', 'getTableCache']),
    onFilterChanged() {
      const filterModel = this.gridApi.getFilterModel();
      localStorage.setItem(`report5-filters`, JSON.stringify(filterModel));
    },
    onColumnChanged(params) {
      const state = params.api.getColumnState();
      this.setTableCache({
        key: 'report-5',
        value: JSON.stringify(state),
      });
    },
    resetFilters() {
      this.gridApi.setFilterModel(null);
    },

    getPinnedBottomRowData() {
      return [
        {
          ...this.totalsItems.products_totals,
          reports: this.totalsItems.reports_totals,
        },
      ];
    },

    setShowZeros(show_zeros) {
      this.setOptions({
        show_zeros,
      });
      this.getData();
    },

    getShowZeros() {
      return this.options?.show_zeros ?? true;
    },

    getFieldName(prefix, name) {
      return prefix ? `${prefix}.${name}` : name;
    },

    showSettingsModal() {
      this.$refs.settingsModal.open();
    },

    setColumns(columns) {
      this.gridApi.setGridOption('columnDefs', columns);
    },

    getClmWidthForQuantity() {
      if (this.options.group_by === 'weeks') {
        return 120;
      }
      return 80;
    },
    toggleNotes(value) {
      this.isNotes = value;
      if (!this.isNotes) {
        this.closeNoteModal();
      }
      this.saveNotesModalStatus(this.isNotes);
    },
    onCellClicked(event) {
      const note = this.notes.find(
        (el) => el.product === event.data.id && event.colDef.field === el.field
      );
      if (!this.isNotes && !note) {
        this.closeNoteModal();
        return;
      }
      const cellRect = event.event.target.getBoundingClientRect();
      if (!event.colDef.field.startsWith('reports')) {
        return;
      }
      this.noteModal.ModalPosition = {
        top: cellRect.top + window.scrollY + cellRect.height,
        left: cellRect.left + window.scrollX,
      };
      this.noteModal.ModalField = event.colDef.field;
      this.noteModal.ModalProduct = event.data.id;
      this.noteModal.ModalVisible = true;
    },
    closeNoteModal() {
      this.noteModal.ModalField = null;
      this.noteModal.ModalProduct = null;
      this.noteModal.ModalVisible = false;
    },
    addNote(value) {
      this.createNote({
        text: value,
        field: this.noteModal.ModalField,
        product: this.noteModal.ModalProduct,
        report: 'Rnp',
      });
      this.closeNoteModal();
    },
    getItemTotalColumns() {
      return [
        {
          field: 'total_in_pieces',
          headerName: 'Заказы (шт)',
          name: 'ordered_in_pieces',
          width: 80,
          cellStyle: (params) => ({
            backgroundColor: this.getColorOfCell(params.data.total_in_pieces),
          }),
          valueFormatter: numberFormatter,
          headerTooltip:
            'Суммарное количество единиц заказанного товара в штуках за выбранный период (из воронки продаж ВБ. Совпадает с отчётом "Еженедельная динамика и анализ продаж")',
        },
        {
          field: 'total_redeemed',
          name: 'redeemed',
          headerName: 'Продажи (шт)',
          width: 80,
          checked: false,
          valueFormatter: numberFormatter,
          headerTooltip:
            'Суммарное количество единиц выкупленного товара в штуках за выбранный период (ориентир - воронка продаж ВБ. Совпадает с отчётом "Еженедельная динамика и анализ продаж")',
        },
        {
          field: 'total_in_rubles',
          name: 'ordered_in_rubles',
          headerName: 'Заказы (руб)',
          width: 120,
          cellStyle: (params) => ({
            backgroundColor: this.getColorOfCell(params.data.total_in_pieces),
          }),
          checked: false,
          valueFormatter: numberFormatter,
          headerTooltip:
            'Суммарное количество заказанного товара в рублях за выбранный период (из воронки продаж ВБ. Сервис тянет каждый заказ по сделанной в моменте цене)',
        },
        {
          field: 'total_to_transfer',
          name: 'to_transfer',
          headerName: 'Продажи (руб)',
          width: 120,
          checked: false,
          valueFormatter: numberFormatter,
          headerTooltip:
            'Суммарное количество выкупленного товара в рублях за выбранный период (из воронки продаж ВБ. Сервис тянет каждый заказ по сделанной в моменте цене)',
        },
        {
          field: 'buyout_percent',
          name: 'buyout_percent',
          headerName: '% выкупа',
          checked: false,
          width: 120,
          valueFormatter: percentFormatterBare,
        },
        {
          field: 'spp_percent',
          name: 'spp_percent',
          headerName: 'СПП %',
          width: 100,
          valueFormatter: percentFormatterBare,
        },
        {
          field: 'predicted_tacos',
          name: 'predicted_tacos',
          headerName: 'Прогнозируемый TACoS %',
          width: 140,
          valueFormatter: percentFormatterBare,
        },
        {
          field: 'total_consumptions',
          name: 'consumption_sum',
          headerName: 'Рекламный расход, руб',
          checked: false,
          width: 120,
          valueFormatter: numberFormatter,
          headerTooltip: 'Рекламный расход по всем РК за выбранный период',
        },
        {
          field: 'total_prices',
          name: 'discount_price',
          headerName: 'Средняя цена товара, руб',
          width: 120,
          checked: false,
          valueFormatter: numberFormatter,
          headerTooltip:
            'Средняя цена товара за выбранный период (считается: складывается каждое изменение цены и делится на количество изменений)',
        },
      ];
    },

    getItemColumns(prefix) {
      return [
        {
          field: this.getFieldName(`reports.${prefix}`, 'ordered_in_pieces'),
          headerName: 'Заказы (шт)',
          width: this.getClmWidthForQuantity(),
          name: 'ordered_in_pieces',
          cellRenderer: 'NoteCell',
          cellStyle: (params) => ({
            backgroundColor: this.getColorOfCell(
              params.data.reports[prefix].ordered_in_pieces
            ),
          }),
          valueFormatter: numberFormatter,
          headerTooltip:
            'Суммарное количество единиц заказанного товара в штуках за выбранный период (из воронки продаж ВБ. Совпадает с отчётом "Еженедельная динамика и анализ продаж")',
        },
        {
          field: this.getFieldName(`reports.${prefix}`, 'redeemed'),
          name: 'redeemed',
          headerName: 'Продажи (шт)',
          width: this.getClmWidthForQuantity(),
          valueFormatter: numberFormatter,
          cellRenderer: 'NoteCell',
          checked: false,
          headerTooltip:
            'Суммарное количество единиц выкупленного товара в штуках за выбранный период (ориентир - воронка продаж ВБ. Совпадает с отчётом "Еженедельная динамика и анализ продаж")',
        },
        {
          field: this.getFieldName(`reports.${prefix}`, 'ordered_in_rubles'),
          headerName: 'Заказы (руб)',
          name: 'ordered_in_rubles',
          cellRenderer: 'NoteCell',
          width: 120,
          cellStyle: (params) => ({
            backgroundColor: this.getColorOfCell(
              params.data.reports[prefix].ordered_in_pieces
            ),
          }),
          checked: false,
          valueFormatter: numberFormatter,
          headerTooltip:
            'Суммарное количество заказанного товара в рублях за выбранный период (из воронки продаж ВБ. Сервис тянет каждый заказ по сделанной в моменте цене)',
        },
        {
          field: this.getFieldName(`reports.${prefix}`, 'to_transfer'),
          name: 'to_transfer',
          headerName: 'Продажи (руб)',
          cellRenderer: 'NoteCell',
          width: 120,
          valueFormatter: numberFormatter,
          checked: false,
          headerTooltip:
            'Суммарное количество выкупленного товара в рублях за выбранный период (из воронки продаж ВБ. Сервис тянет каждый заказ по сделанной в моменте цене)',
        },
        {
          field: this.getFieldName(`reports.${prefix}`, 'buyout_percent'),
          name: 'buyout_percent',
          headerName: '% выкупа',
          cellRenderer: 'NoteCell',
          width: 120,
          valueFormatter: percentFormatterBare,
        },
        {
          field: this.getFieldName(`reports.${prefix}`, 'spp_percent'),
          name: 'spp_percent',
          headerName: 'СПП %',
          cellRenderer: 'NoteCell',
          width: 100,
          valueFormatter: percentFormatterBare,
        },
        {
          field: this.getFieldName(`reports.${prefix}`, 'predicted_tacos'),
          name: 'predicted_tacos',
          headerName: 'Прогнозируемый TACoS %',
          cellRenderer: 'NoteCell',
          width: 120,
          valueFormatter: percentFormatterBare,
        },
        {
          field: this.getFieldName(`reports.${prefix}`, 'consumption_sum'),
          name: 'consumption_sum',
          headerName: 'Рекламный расход',
          cellRenderer: 'NoteCell',
          width: 120,
          checked: false,
          valueFormatter: numberFormatter,
          headerTooltip: 'Рекламный расход по всем РК за данный день',
        },
        {
          field: this.getFieldName(`reports.${prefix}`, 'discount_price'),
          name: 'discount_price',
          headerName: 'Средняя цена товара',
          cellRenderer: 'NoteCell',
          width: 120,
          checked: false,
          valueFormatter: numberFormatter,
          headerTooltip:
            'Средняя цена товара за выбранный период (считается: складывается каждое изменение цены и делится на количество изменений)',
        },
      ];
    },

    getReportsColumns(reports) {
      return Object.entries(reports).map(([key, value]) => {
        return {
          headerName: value.date_interval,
          groupName: 'date',
          children: this.getItemColumns(key),
        };
      });
    },

    generateTableColumns() {
      const savedSorting = localStorage.getItem('report5-sort');
      this.columns = [
        {
          headerGroupComponent: 'RnpTableHeaderSettings',
          headerGroupComponentParams: {
            showSettingsModal: this.showSettingsModal,
            toggleNotes: this.toggleNotes,
          },
          groupName: 'settings',
          children: [
            {
              field: 'image',
              cellRenderer: 'TableImageComponent',
              cellRendererParams: (params) => {
                return {
                  link: params.node.data.wb_catalog_url,
                };
              },
              headerName: 'Фото',
              pinned: 'left',
              tooltipField: 'image',
              tooltipComponent: 'ImgTooltip',
              sortable: false,
              disableCheckbox: true,
              width: 60,
            },
            {
              field: 'subject',
              headerName: 'Предмет',
              name: 'subject',
              pinned: 'left',
              filter: 'TableFilterModal',
              width: 110,
            },
            {
              field: 'brand',
              headerName: 'Бренд',
              name: 'brand',
              pinned: 'left',
              filter: 'TableFilterModal',
              width: 110,
            },
            {
              field: 'nm_id',
              headerName: 'Артикул WB',
              name: 'nm_id',
              pinned: 'left',
              filter: 'TableFilterModal',
              width: 110,
              cellRenderer: 'LinkCell',
              cellRendererParams: (params) => {
                return {
                  link: `/reports/products-statistic/${params.value}`,
                  targetBlank: true,
                };
              },
            },
            {
              field: 'sa_name',
              headerName: 'Арт продавца',
              name: 'sa_name',
              pinned: 'left',
              filter: 'TableFilterModal',
              width: 110,
            },
          ],
        },
        {
          children: [
            {
              field: 'stock_week',
              name: 'stock_week',
              headerName: 'Остаток неделю назад, шт',
              filter: 'TableFilterModal',
              valueFormatter: numberFormatter,
              headerTooltip:
                'Остаток товара в шт за семь дней назад - от сегодняшнего дня.',
              checked: false,
            },
            {
              field: 'stock_today',
              name: 'stock_today',
              headerName: 'Остаток сегодня, шт',
              filter: 'TableFilterModal',
              valueFormatter: numberFormatter,
              headerTooltip: 'Остаток товара в шт на сегодня',
              width: 120,
            },
            {
              field: 'orders_speed',
              name: 'orders_speed',
              headerName: 'Скорость заказов',
              valueFormatter: roundedNumberFormatter,
              width: 100,
            },
            {
              field: 'enough_for',
              name: 'enough_for',
              headerName: 'Хватит на, дней',
              valueFormatter: roundedNumberFormatter,
              width: 100,
            },
            {
              field: 'turnover',
              name: 'turnover',
              headerName: 'Оборачиваемость',
              valueFormatter: roundedNumberFormatter,
              checked: false,
            },
          ],
        },
        {
          headerName: 'Итог',
          groupName: 'total',
          children: this.getItemTotalColumns(),
        },
        ...this.getReportsColumns(this.items[0].reports),
      ];
      if (savedSorting) {
        const parsedSorting = JSON.parse(savedSorting);
        this.columns = this.getSortColumns(this.columns, parsedSorting);
      }
    },

    getSortColumns(columns, parsedSorting) {
      return columns.reduce((acc, column) => {
        if (column.children) {
          column.children = this.getSortColumns(column.children, parsedSorting);
          return [...acc, column];
        }
        return column.field === parsedSorting.order_field
          ? [
              ...acc,
              {
                ...column,
                sort: parsedSorting.order_type,
              },
            ]
          : [...acc, column];
      }, []);
    },

    getColorOfCell(itemAmount) {
      if (itemAmount == 0 || !itemAmount) {
        return ' #efefef';
      }
      if (itemAmount <= 5) {
        return '#f5faf2';
      }
      if (itemAmount <= 10) {
        return ' #eaf4e5';
      }
      if (itemAmount <= 20) {
        return '#dfeed8';
      }
      if (itemAmount <= 30) {
        return '#d4e8cb';
      }
      if (itemAmount <= 50) {
        return ' #c9e2be';
      }
      if (itemAmount < 80) {
        return '#bfdcb1';
      }
      if (itemAmount <= 100) {
        return '#b4d6a4';
      }
      if (itemAmount <= 150) {
        return '#a9d097';
      }
      if (itemAmount <= 1000) {
        return ' #9eca8a';
      }
      return '#93c47d';
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.generateTableColumns();
      const savedFilters = localStorage.getItem(`report5-filters`);
      if (savedFilters) {
        setTimeout(() => {
          this.gridApi.setFilterModel(JSON.parse(savedFilters));
        }, 0);
      }
      setTimeout(() => {
        this.getTableCache({
          key: 'report-5',
        });
      }, 100);
    },
  },
};
</script>
<style lang="scss">
@import '~ag-grid-community/styles/ag-grid.css';
@import '~ag-grid-community/styles/ag-theme-alpine.css';
.table,
.custom-grid .ag-root {
  height: calc(100vh - 230px);
}

.custom-grid .ag-header {
  background-color: #f8f8f8;
  border-bottom: 2px solid #ccc;
}

.custom-grid .ag-row {
  font-size: 14px;
  border-bottom: 1px solid #f1f1f1;
  height: 32px;

  &:nth-child(2n) {
    background-color: #fafafa;
  }
}

.custom-grid .ag-row:hover {
  background-color: #f1f1f1;
}

.custom-grid .ag-cell {
  text-align: center;
  font-size: 12px;
}

.custom-grid .ag-header-cell {
  font-weight: 500;
  text-align: center;
  background-color: #fcf8ff;
  color: #908f90;
  font-size: 14px;
  white-space: wrap;
}

.custom-grid {
  .ag-root-wrapper {
    border: 1px solid #f1f1f1;
    &-body {
      padding: 0;
    }
  }

  .ag-header-container {
    .table-v2-btn-settings {
      display: none;
    }
  }

  .ag-header {
    border-bottom: 1px solid #f1f1f1;
  }
  .ag-pinned-left-header {
    border-right: none;
  }
  .ag-header-group-cell-label {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ag-header-group-text {
    color: #908f90;
    font-size: 12px;
  }

  .ag-header-cell,
  .ag-header-cell-label {
    font-weight: 500;
    text-align: center;
    background-color: #fcf8ff;
    color: #908f90;
    font-size: 12px;
    white-space: wrap;
  }

  .ag-header-row:not(:first-child)
    .ag-header-cell:not(.ag-header-span-height.ag-header-span-total),
  .ag-floating-bottom,
  .ag-root-wrapper {
    border-top: 1px solid #f1f1f1;
  }

  .ag-cell {
    &.ag-cell-last-left-pinned {
      &:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
        border-right: none;
      }
    }
  }
}

.border .custom-grid .ag-group-cell {
  font-weight: bold;
  background-color: #f2f2f2;
}

.custom-grid .ag-header-group-cell-label {
  margin: auto;
}

.ag-header {
  min-height: 110px !important;
}

.ag-header-cell {
  height: 70px !important;
}
</style>
