const dayjs = require('dayjs');

export const numberFormatter = (params) => {
  return params.value ? params.value.toLocaleString('ru-RU') : params.value;
};

export const roundedNumberFormatter = (params) => {
  return Number(params.value).toFixed(0);
};

export const percentFormatter = (params) => {
  return params.value ? Number(params.value).toFixed(2) + '%' : params.value;
};

export const percentFormatterFloat = (params) => {
  return params.value ? Number(params.value).toFixed(2) + '%' : params.value;
};

export const percentFormatterBare = (params) => {
  return params.value ? Number(params.value).toFixed(2) : params.value;
};

export const currencyFormatter = (params, sign = '₽') => {
  const formatter = new Intl.NumberFormat('ru-RU', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formatter.format(params.value) + ` ${sign}`;
};

export const formatDate = (value, format = 'DD.MM.YYYY HH:mm') => {
  if (value === null) {
    return '';
  }
  const date = dayjs(value);
  return date.format(format);
};
