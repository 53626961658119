export const state = {
  loading: false,
  totalsItems: {
    reports_totals: {},
    products_totals: {},
  },
  items: [],
  notes: [],
  isNotes: false,
  options: null,
  filters: null,
  hasProductsSuitableSubscription: true,
};
