<template>
  <div>
    <div class="wb-report-3-chart-1">
      <div class="d-flex justify-content-between title" v-if="isShowTitles">
        <div>Динамика</div>
      </div>
      <div class="flex-grow-1">
        <LineChart :data="data" />
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from '@/components/charts/LineChart';
import { mapGetters } from 'vuex';

export default {
  name: 'dynamicChart',
  props: {
    data: {
      type: Object,
      required: false,
    },
    isShowTitles: {
      type: Boolean,
      required: false,
      default: true,
    },
    title: {
      type: String,
      required: false,
      default: 'Общая статистика артикула по активным кампаниям за период',
    },
  },
  components: {
    LineChart,
  },

  computed: {
    ...mapGetters(['total_drr']),
  },
};
</script>

<style scoped>
.badge {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-left: 5px;
  vertical-align: middle;
}
</style>
