<template>
  <base-modal
    class="note-modal"
    @closeModal="closeModal()"
    :is-visible="isVisible"
  >
    <div class="modal-header">
      <div class="user-info">
        <div class="name">{{ me.first_name }}</div>
        <div class="date">{{ formatDate(note?.created_at, 'DD.MM.YYYY') }}</div>
      </div>
    </div>
    <div class="edit-dots" v-if="note">
      <div class="edit-icon" @click="editPopup = true">
        <svg
          width="14"
          height="4"
          viewBox="0 0 14 4"
          fill="red"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.75 2C8.75 2.34612 8.64737 2.68446 8.45507 2.97225C8.26278 3.26003 7.98947 3.48434 7.6697 3.61679C7.34993 3.74924 6.99806 3.7839 6.65859 3.71637C6.31913 3.64885 6.00731 3.48218 5.76256 3.23744C5.51782 2.9927 5.35115 2.68087 5.28363 2.34141C5.2161 2.00194 5.25076 1.65007 5.38321 1.3303C5.51567 1.01053 5.73997 0.737221 6.02775 0.544928C6.31554 0.352636 6.65388 0.25 7 0.25C7.46413 0.25 7.90925 0.434375 8.23744 0.762563C8.56563 1.09075 8.75 1.53587 8.75 2ZM2 0.25C1.65388 0.25 1.31554 0.352636 1.02775 0.544928C0.739967 0.737221 0.515665 1.01053 0.383212 1.3303C0.250758 1.65007 0.216102 2.00194 0.283627 2.34141C0.351151 2.68087 0.517822 2.9927 0.762564 3.23744C1.00731 3.48218 1.31913 3.64885 1.65859 3.71637C1.99806 3.7839 2.34993 3.74924 2.6697 3.61679C2.98947 3.48434 3.26278 3.26003 3.45507 2.97225C3.64737 2.68446 3.75 2.34612 3.75 2C3.75 1.53587 3.56563 1.09075 3.23744 0.762563C2.90925 0.434375 2.46413 0.25 2 0.25ZM12 0.25C11.6539 0.25 11.3155 0.352636 11.0278 0.544928C10.74 0.737221 10.5157 1.01053 10.3832 1.3303C10.2508 1.65007 10.2161 2.00194 10.2836 2.34141C10.3512 2.68087 10.5178 2.9927 10.7626 3.23744C11.0073 3.48218 11.3191 3.64885 11.6586 3.71637C11.9981 3.7839 12.3499 3.74924 12.6697 3.61679C12.9895 3.48434 13.2628 3.26003 13.4551 2.97225C13.6474 2.68446 13.75 2.34612 13.75 2C13.75 1.77019 13.7047 1.54262 13.6168 1.3303C13.5288 1.11798 13.3999 0.925066 13.2374 0.762563C13.0749 0.600061 12.882 0.471156 12.6697 0.383211C12.4574 0.295265 12.2298 0.25 12 0.25Z"
            fill="#908F90"
          />
        </svg>
      </div>
      <div class="edit-popup" v-if="editPopup">
        <div class="edit-btn" @click="enableEditMode">
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.7586 4.73214L12.268 1.24073C12.1519 1.12463 12.0141 1.03253 11.8624 0.96969C11.7107 0.906853 11.5482 0.874512 11.384 0.874512C11.2198 0.874512 11.0572 0.906853 10.9056 0.96969C10.7539 1.03253 10.6161 1.12463 10.5 1.24073L0.866412 10.8751C0.749834 10.9908 0.657407 11.1284 0.594506 11.2801C0.531604 11.4318 0.499482 11.5945 0.500006 11.7587V15.2501C0.500006 15.5816 0.631702 15.8996 0.866123 16.134C1.10054 16.3684 1.41849 16.5001 1.75001 16.5001H5.24141C5.40563 16.5006 5.5683 16.4685 5.71999 16.4056C5.87168 16.3427 6.00935 16.2503 6.12501 16.1337L15.7586 6.5001C15.8747 6.38403 15.9668 6.24622 16.0296 6.09454C16.0925 5.94286 16.1248 5.7803 16.1248 5.61612C16.1248 5.45194 16.0925 5.28937 16.0296 5.1377C15.9668 4.98602 15.8747 4.84821 15.7586 4.73214ZM5.24141 15.2501H1.75001V11.7587L8.62501 4.8837L12.1164 8.3751L5.24141 15.2501ZM13 7.49073L9.5086 4.0001L11.3836 2.1251L14.875 5.61573L13 7.49073Z"
              fill="#191F2E"
            />
          </svg>
          <span>Редактировать</span>
        </div>
        <div class="edit-btn edit-btn_delete" @click="removeNote">
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.875 2.75H11.75V2.125C11.75 1.62772 11.5525 1.15081 11.2008 0.799175C10.8492 0.447544 10.3723 0.25 9.875 0.25H6.125C5.62772 0.25 5.15081 0.447544 4.79917 0.799175C4.44754 1.15081 4.25 1.62772 4.25 2.125V2.75H1.125C0.95924 2.75 0.800269 2.81585 0.683058 2.93306C0.565848 3.05027 0.5 3.20924 0.5 3.375C0.5 3.54076 0.565848 3.69973 0.683058 3.81694C0.800269 3.93415 0.95924 4 1.125 4H1.75V15.25C1.75 15.5815 1.8817 15.8995 2.11612 16.1339C2.35054 16.3683 2.66848 16.5 3 16.5H13C13.3315 16.5 13.6495 16.3683 13.8839 16.1339C14.1183 15.8995 14.25 15.5815 14.25 15.25V4H14.875C15.0408 4 15.1997 3.93415 15.3169 3.81694C15.4342 3.69973 15.5 3.54076 15.5 3.375C15.5 3.20924 15.4342 3.05027 15.3169 2.93306C15.1997 2.81585 15.0408 2.75 14.875 2.75ZM5.5 2.125C5.5 1.95924 5.56585 1.80027 5.68306 1.68306C5.80027 1.56585 5.95924 1.5 6.125 1.5H9.875C10.0408 1.5 10.1997 1.56585 10.3169 1.68306C10.4342 1.80027 10.5 1.95924 10.5 2.125V2.75H5.5V2.125ZM13 15.25H3V4H13V15.25ZM6.75 7.125V12.125C6.75 12.2908 6.68415 12.4497 6.56694 12.5669C6.44973 12.6842 6.29076 12.75 6.125 12.75C5.95924 12.75 5.80027 12.6842 5.68306 12.5669C5.56585 12.4497 5.5 12.2908 5.5 12.125V7.125C5.5 6.95924 5.56585 6.80027 5.68306 6.68306C5.80027 6.56585 5.95924 6.5 6.125 6.5C6.29076 6.5 6.44973 6.56585 6.56694 6.68306C6.68415 6.80027 6.75 6.95924 6.75 7.125ZM10.5 7.125V12.125C10.5 12.2908 10.4342 12.4497 10.3169 12.5669C10.1997 12.6842 10.0408 12.75 9.875 12.75C9.70924 12.75 9.55027 12.6842 9.43306 12.5669C9.31585 12.4497 9.25 12.2908 9.25 12.125V7.125C9.25 6.95924 9.31585 6.80027 9.43306 6.68306C9.55027 6.56585 9.70924 6.5 9.875 6.5C10.0408 6.5 10.1997 6.56585 10.3169 6.68306C10.4342 6.80027 10.5 6.95924 10.5 7.125Z"
              fill="#F44336"
            />
          </svg>

          <span>Удалить</span>
        </div>
      </div>
    </div>
    <div class="modal-body">
      <textarea
        v-if="!this.note || this.editMode"
        type="text"
        v-model="value"
        class="note-input"
        placeholder="Оставьте комментарий"
      />
      <p v-else style="white-space: pre-line">
        {{ value }}
      </p>
    </div>
    <form
      class="modal-footer"
      @submit.prevent="submit"
      v-if="!this.note || this.editMode"
    >
      <button class="apply-all-btn" @click="closeModal">Отмена</button>
      <button class="apply-btn" type="submit" v-if="this.note">Изменить</button>
      <button class="apply-btn" type="submit" v-else>Добавить</button>
    </form>
  </base-modal>
</template>
<script>
import BaseModal from '@/views/reports/report-6/components/modals/BaseModal.vue';
import { mapGetters, mapActions } from 'vuex';
import { formatDate } from '../../../../../helpers/numberFormatter';

export default {
  name: 'NoteModal',
  components: { BaseModal },
  props: ['isVisible', 'productId', 'field'],
  emits: ['closeModal'],
  data() {
    return {
      value: '',
      note: null,
      editPopup: false,
      editMode: false,
    };
  },
  computed: {
    ...mapGetters('report5', ['notes']),
    ...mapGetters(['me']),
  },
  watch: {
    productId: {
      handler() {
        this.editPopup = false;
        this.note = this.notes.find(
          (el) => el.product === this.productId && el.field === this.field
        );
        if (this.note) {
          this.value = this.note.text;
        } else {
          this.value = '';
        }
      },
      immediate: true,
    },
  },
  methods: {
    formatDate,
    ...mapActions('report5', ['deleteNote']),
    closeModal() {
      this.value = '';
      this.editPopup = false;
      this.editMode = false;
      this.$emit('closeModal');
    },
    submit() {
      if (!this.value) {
        this.closeModal();
      }
      this.$emit('createNote', this.value);
      this.value = '';
      this.editMode = false;
    },
    removeNote() {
      this.deleteNote(this.note.id);
      this.closeModal();
    },
    enableEditMode() {
      this.editMode = true;
      this.editPopup = false;
    },
  },
};
</script>

<style scoped lang="scss">
.note-modal {
    width: 300px;
}

.edit-dots {
  position: absolute;
  right: 25px;
  z-index: 100;

  & .edit-icon {
    cursor: pointer;

    & svg {
      cursor: pointer;
      width: 20px;
      height: 20px;
    }
  }

   & .edit-popup {
      position: absolute;
      background-color: white;
      border-radius: 10px;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.08);
      width: 200px;
      height: 100px;
      top: -60px;
      left: -200px;
      padding: 15px;

      & .edit-btn {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        padding: 5px;
      }

     & .edit-btn_delete {
       color: #F44336;
     }
    }
}

.modal-header {
  padding: 10px;
  display: flex;
  justify-content: left;

  & .avatar {
    width: 40px;
    height: 40px;
    color: white;
    font-size: 16px;
    background-color: #FF9500;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

      & .user-info {
        display: flex;
        flex-direction: column;
        line-height: 1.2;
        & .name {
          font-size: 14px;
        }

        & .date {
          font-size: 10px;
          color: #908F90;
        }
    }
}

.modal-body {
    padding: 10px;
    display: flex;
    width: 100%;
    overflow-y: auto;
    margin-bottom: 5px;
}

.modal-footer {
    display: flex;
    gap: 10px;
    border-top: 15px;
    background-color: white;
    padding: 10px 10px 15px;
}

.apply-btn {
    background-color: #541CAB;
    color: white;
    border: none;
    border-radius: 10px;
    width: 48%;
}

.apply-all-btn {
    color: #541CAB;
    background-color: #EEE8F7;
    border: none;
    border-radius: 15px;
    width: 48%;
}

.note-input {
    height: 100px;
    width: 100%;
    border: 1px solid #541CAB;
    border-radius: 10px;
    padding: 10px;
    margin-top: 15px;
}
</style>
