<template>
  <div class="filters">
    <div class="third-report-card p-3">
      <div
        class="third-report-card__date-filter mr-1"
        v-if="!hideOptions.includes('range')"
      >
        <div class="d-flex period-filter me-3">
          <label class="d-flex align-items-center me-3 mb-0"> Период </label>
          <b-input-group style="width: 160px">
            <b-input-group-prepend
              class="third-report-card_sort-and-filter__input-prepend"
            >
              <b-form-datepicker
                v-model="filters.from"
                start-weekday="1"
                button-only
              >
                <template #button-content>
                  <img height="20" src="/img/datepicker-icon.svg" />
                </template>
              </b-form-datepicker>
            </b-input-group-prepend>
            <b-form-input
              class="third-report-card_sort-and-filter__input"
              type="text"
              placeholder="17/02/2023"
              v-model="formattedFrom"
              disabled
              style="height: 45px"
            ></b-form-input>
          </b-input-group>
        </div>

        <div class="d-flex period-filter">
          <label class="d-flex align-items-center me-3 mb-0"> по </label>
          <b-input-group style="width: 160px">
            <b-input-group-prepend
              class="third-report-card_sort-and-filter__input-prepend"
            >
              <b-form-datepicker
                start-weekday="1"
                v-model="filters.to"
                button-only
              >
                <template #button-content>
                  <img height="20" src="/img/datepicker-icon.svg" />
                </template>
              </b-form-datepicker>
            </b-input-group-prepend>
            <b-form-input
              type="text"
              class="third-report-card_sort-and-filter__input"
              placeholder="17/02/2023"
              v-model="formattedTo"
              disabled
              style="height: 45px"
            ></b-form-input>
          </b-input-group>
        </div>
      </div>

      <div
        class="third-report-card__date-filter ml-1"
        v-if="!hideOptions.includes('range_btns')"
      >
        <b-button-group class="wb-button-group">
          <b-button
            style="font-size: 13px"
            :pressed="checkDate(1)"
            @click="setDate(1)"
          >
            Вчера
          </b-button>
          <b-button
            style="font-size: 13px"
            :pressed="checkDate(7)"
            @click="setDate(7)"
          >
            7 дней
          </b-button>
          <b-button
            style="font-size: 13px"
            :pressed="checkDate(14)"
            @click="setDate(14)"
          >
            14 дней
          </b-button>
          <b-button
            style="font-size: 13px"
            :pressed="checkDate(30)"
            @click="setDate(30)"
          >
            30 дней
          </b-button>
        </b-button-group>
      </div>

      <div class="flex justify-content-end text-right filters__wrap_export">
        <ReportsGroupBtnMenu :options="groups" :onSelect="onSelectGroup" />

        <b-btn @click="downloadReport" class="wb-btn export">
          <div class="export__container">
            <img src="/img/export-icon-2.svg" />
            <span>Export</span>
          </div>
        </b-btn>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import { includes } from 'core-js/internals/array-includes';
import ReportsGroupBtnMenu from '@/components/reports-groups/ReportsGroupBtnMenu.vue';
import {getReportDateRange, saveReportDateRange} from "@/utils/reportDateFilters";

export default {
  name: 'filterAndsort',
  components: { ReportsGroupBtnMenu },
  props: {
    article: {
      type: Number | String,
      default: '',
      required: false,
    },
    name: {
      type: String,
      default: '',
    },
    hideOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    disabledOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    onChangeFilter: {
      type: Function,
      required: false,
    },
    groups: {
      type: Array,
    },
    onSelectGroup: {
      type: Function,
    },
    activeGroup: {
      type: String,
    },
  },
  data() {
    return {
      tableHeight: 0,
      sortKey: null,
      isWatchCompetitor: false,
      newKeyword: '',
      newDirectCompetitor: '',
      filters: {
        from:
          this.$route.query.from ||
          moment().subtract(7, 'days').format('YYYY-MM-DD'),
        to: this.$route.query.to || moment().format('YYYY-MM-DD'),
      },
    };
  },
  computed: {
    formattedFrom() {
      return moment(this.filters.from).format('DD/MM/YYYY');
    },
    formattedTo() {
      return moment(this.filters.to).format('DD/MM/YYYY');
    },
  },
  watch: {
    filters: {
      handler() {
        const formattedTo = moment(this.filters.to).format('YYYY-MM-DD');
        const formattedFrom = moment(this.filters.from).format('YYYY-MM-DD');
         saveReportDateRange({
          end_date: this.filters.to,
          start_date: this.filters.from,
        });
        this.emitDateFilterHandler({ to: formattedTo, from: formattedFrom });
      },
      deep: true,
    },
  },
  methods: {
    includes,
    ...mapActions(['uploadReport']),
    downloadReport() {
      this.uploadReport({
        type: 'dyn_advert',
        filters: {
          start_date: this.filters.from,
          end_date: this.filters.to,
          group_by: this.activeGroup,
          advert: this.article,
        },
      });
    },
    setFilterByQuery() {
      this.filters.from = moment().subtract(7, 'days').format('YYYY-MM-DD');
      this.filters.to = moment().subtract(1, 'days').format('YYYY-MM-DD');
    },
    setQuery() {
      this.$router.replace({
        name: 'productsStatistic',
        query: {
          from: this.filters.from || '',
          to: this.filters.to || '',
        },
      });
    },
    checkDate(interval) {
      let days = interval;

      const now = moment().subtract(1, 'days');
      const pre = moment().subtract(days, 'days');

      return (
        now.isSame(moment(this.filters.to), 'day') &&
        pre.isSame(moment(this.filters.from), 'day')
      );
    },

    setDate(interval) {
      const now = moment();
      const pre = moment().subtract(interval - 1, 'days');

      this.filters.from = pre.subtract(1, 'days').format('YYYY-MM-DD');
      this.filters.to = now.subtract(1, 'days').format('YYYY-MM-DD');
          saveReportDateRange({
        end_date: this.filters.to,
        start_date: this.filters.from,
      });
    },
    emitDateFilterHandler(data) {
      this.$emit('dateFilterHandler', data);
    },
  },
  mounted() {
   const dtRange = getReportDateRange();
    if (dtRange) {
      this.$nextTick(() => {
        this.filters.from = dtRange.start_date;
        this.filters.to = dtRange.end_date;
      });
    } else {
      this.setFilterByQuery();
    }
  },
};
</script>

<style lang="scss" scoped>
.add-input {
  height: 38px;
  border-left: none !important;
  border-right: 1px solid #ece6f0 !important;
  border-radius: 0 !important;
  border-top: none !important;
  border-bottom: none !important;
}
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 2221; /* Чтобы заголовок оставался наверху других элементов */
  background-color: #fcf8ff !important;
}
table thead {
  position: sticky;
  top: -1px;
  background-color: white;
  box-shadow: none;
  z-index: 2;
}
table tbody td.sticky-col {
  position: sticky;
  left: -1px;
  z-index: 1;
  background-color: white;
}
.product-statistic-table thead th.sticky-col {
  position: sticky;
  left: 0;
  background-color: #fcf8ff;
  z-index: 1;
}
.table-container {
  overflow: auto;
  position: relative;
}

.product-statistic-table thead:first-child {
  position: sticky;
  top: 0;
  background: #fcf8ff;
  z-index: 1;
}
table thead {
  position: sticky;
  top: -1px;
  background-color: white;
  z-index: 2;
}
thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
}
/* Задаем стили для первой колонки */
table tbody td.sticky-col {
  position: sticky;
  left: -1px;
  z-index: 1;
  background-color: white;
}

.table-scroll {
  display: grid;
  grid-template-columns: auto repeat(auto-fit, minmax(100px, 1fr));
  max-width: 100%;
  overflow-x: scroll;
  display: none;
}

.third-report-card__date-filter {
  margin-left: 0;
}

.filters {
  margin-bottom: 0;
  border: 1px solid #f1f1f1;
  border-bottom: none;

  & .third-report-card {
    margin-bottom: 0;
    height: auto;
    padding: 19px 24px !important;

    & > div {
      width: 100%;
      display: grid !important;
      grid-template-columns: auto auto auto;
    }
  }

  & .wrap_filters_item {
    gap: 1rem;
    align-items: center;
  }
}
</style>
